<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

const { $lang, $assets } = useNuxtApp()

const AuthStore = useAuthStore()
const Route = useRoute()

const isLoggedIn = ref(AuthStore.IsAuth)

const notificationsAndMessagesCount = computed(
    () => AuthStore.UserActivitiesCount.notifications + AuthStore.UserActivitiesCount.messages,
)

const $dropdown = ref()

onClickOutside($dropdown, () => {
    toggleMenu(false)
})

const showMenu = ref(false)

const toggleMenu = (show: boolean) => {
    showMenu.value = show
}

const signIn = () => {
    AuthStore.setAuthModal({
        type: 'sign-in',
    })
}

const path = computed(() => Route.fullPath)

watch(path, () => {
    toggleMenu(false)
})

const RootStore = useRootStore()

const config = useRuntimeConfig()
const variant = config.public.variant

const shareIconSrc = computed(() => {
    if (isLoggedIn.value) {
        return variant === 'bchollos' || variant === 'megadescuentos'
            ? $assets.white.plus
            : $assets.black.plus
    } else {
        return $assets.white.plus
    }
})

const menuOptions = [
    {
        title: $lang.components.layoutHeaderUser.dashboard,
        icon: {
            active: $assets.primary.userAccount,
            inactive: $assets.gray.userAccount,
        },
        path: `/${$lang.routes.dashboard}`,
    },
    {
        title: $lang.components.layoutHeaderUser.notifications,
        icon: {
            active: $assets.primary.userNotifications,
            inactive: $assets.gray.userNotifications,
        },
        notifications: AuthStore.UserActivitiesCount.messages,
        path: `/${$lang.routes.notifications}`,
    },
    {
        title: $lang.components.layoutHeaderUser.messages,
        icon: {
            active: $assets.primary.userMessages,
            inactive: $assets.gray.userMessages,
        },
        notifications: AuthStore.UserActivitiesCount.notifications,
        path: `/${$lang.routes.inbox}`,
    },
    {
        title: $lang.components.layoutHeaderUser.saved,
        icon: { active: $assets.primary.userSaved, inactive: $assets.gray.userSaved },
        path: `/${$lang.routes.dashboard}/${$lang.routes.saved}`,
    },
    {
        title: $lang.components.layoutHeaderUser.coins,
        icon: { active: $assets.primary.userCoins, inactive: $assets.gray.userCoins },
        path: `/${$lang.routes.dashboard}/${$lang.routes.coins}`,
    },
    {
        title: $lang.components.layoutHeaderUser.rewards,
        icon: {
            active: $assets.primary.userThrophies,
            inactive: $assets.gray.userThrophies,
        },
        path: `/${$lang.routes.dashboard}/${$lang.routes.rewards}`,
    },
    {
        title: $lang.components.layoutHeaderUser.settings,
        icon: {
            active: $assets.primary.userSettings,
            inactive: $assets.gray.userSettings,
        },
        path: `/${$lang.routes.dashboard}/${$lang.routes.settings}`,
    },
    {
        title: $lang.components.layoutHeaderUser.close_session,
        icon: $assets.gray.userLogout,
        handler: () => {
            AuthStore.signOut(Route.name as string)
            toggleMenu(false)
        },
    },
]
</script>

<template>
    <div
        class="header-user-buttons"
        :class="isLoggedIn ? 'gap-x-1 md:flex-row' : 'flex-row-reverse gap-x-2 px-1'"
    >
        <button
            v-if="!isLoggedIn"
            type="button"
            class="header-user-buttons__secondary"
            :class="
                $config.public.variant === 'bchollos'
                    ? 'bg-site-primary-dark text-site-primary-text-contrast'
                    : 'bg-site-secondary text-site-secondary-text-contrast'
            "
            @click="signIn"
        >
            <span v-if="$config.public.variant !== 'megadescuentos'">{{
                $lang.components.layoutHeaderUser.join_free
            }}</span>
            <img v-else :src="$assets.white.userOutline" alt="iniciar sesión icono" />
        </button>
        <template v-else>
            <NuxtLink
                no-prefetch
                :to="`/${$lang.routes.notifications}`"
                class="header-user-buttons__button"
                rel="follow"
            >
                <picture class="relative">
                    <img
                        :src="$assets.gray.notifications"
                        :alt="$lang.components.layoutHeaderUser.notifications_panel"
                        :title="$lang.components.layoutHeaderUser.notifications_panel"
                        width="20px"
                        height="20px"
                    />
                    <div
                        v-if="AuthStore.UserActivitiesCount.notifications"
                        class="header-user-buttons__button-info"
                    >
                        {{ AuthStore.UserActivitiesCount.notifications }}
                    </div>
                </picture>
            </NuxtLink>

            <NuxtLink
                no-prefetch
                :to="`/${$lang.routes.inbox}`"
                class="header-user-buttons__button"
                rel="follow"
            >
                <picture class="relative">
                    <img
                        :src="$assets.gray.messages"
                        :alt="$lang.components.layoutHeaderUser.messages_panel"
                        :title="$lang.components.layoutHeaderUser.messages_panel"
                        width="20px"
                        height="20px"
                    />
                    <div
                        v-if="AuthStore.UserActivitiesCount.messages"
                        class="header-user-buttons__button-info"
                    >
                        {{ AuthStore.UserActivitiesCount.messages }}
                    </div>
                </picture>
            </NuxtLink>
            <div
                class="header-user-buttons__profile-photo"
                :class="showMenu ? 'bg-white' : 'border-transparent'"
            >
                <button type="button" @click="() => toggleMenu(!showMenu)">
                    <MiscUserPic
                        v-if="AuthStore.UserData"
                        :user="AuthStore.UserData"
                        :badge="false"
                        :notifications-count="notificationsAndMessagesCount"
                        class="h-10 w-10"
                    />
                </button>
                <Transition name="user">
                    <HeaderUserDropdown
                        v-if="showMenu"
                        ref="$dropdown"
                        :options="menuOptions"
                        class="header-user-buttons__profile-dropdown"
                    />
                </Transition>
            </div>
        </template>

        <MiscOverlay v-if="showMenu" mode="dark" class="header-user-buttons__overlay" />

        <!-- class="text-white" -->
        <NuxtLink
            no-prefetch
            :to="`/${$lang.routes.share}`"
            :class="{
                'header-user-buttons__secondary': isLoggedIn,
                'header-user-buttons__btn-normal': !isLoggedIn,
                'bg-site-primary-dark text-site-primary-text-contrast':
                    $config.public.variant === 'bchollos' && isLoggedIn,
                'bg-site-secondary text-site-secondary-text-contrast':
                    $config.public.variant !== 'bchollos' && isLoggedIn,
                'text-white': !isLoggedIn,
            }"
            rel="follow"
            :aria-label="$lang.components.layoutHeaderUser.share_discounts"
        >
            <img
                :src="shareIconSrc"
                height="5"
                width="17"
                :alt="$lang.components.layoutHeaderUser.share_discounts"
                :title="$lang.components.layoutHeaderUser.share_discounts"
            />
            <span class="hidden md:inline">{{ $lang.components.layoutHeaderUser.share }}</span>
        </NuxtLink>
    </div>
</template>

<style lang="postcss" scoped>
.header-user-buttons {
    @apply flex h-full items-center pr-2 xs:pr-0;
    &__overlay {
        @apply fixed z-[100] h-screen w-screen md:hidden;
        top: calc(var(--total-header-height, 0px) - var(--headband-height, 0px));
    }
    &__button {
        @apply hidden flex-none rounded-lg px-0.5 md:block;
        picture {
            @apply block h-10 w-10 rounded-lg bg-black bg-opacity-10;
            img {
                @apply h-full w-full object-contain p-3;
            }
        }
        &-info {
            @apply absolute -top-1 z-[500] block min-w-[20px] rounded-full bg-site-primary p-0.5 text-center text-xs text-white saturate-150 filter;
        }
    }

    &__profile-photo {
        @apply relative z-[999] flex h-full items-center px-1 shadow-2xl;
        button {
            @apply block h-10 w-10 rounded-full;
        }
    }
    &__profile-dropdown {
        top: calc(var(--total-header-height, 0px) - var(--headband-height, 0px));

        @apply fixed right-[0px] z-[999] w-64 md:absolute md:top-full md:w-52 md:shadow-xl;
    }

    &__btn-normal,
    &__secondary {
        @apply flex items-center justify-center rounded-lg p-2 lg:transition;
    }

    &__btn-normal {
        @apply appearance-none rounded-lg border border-white border-opacity-80 bg-transparent;
        span {
            @apply hidden flex-grow sm:block;
        }
        img {
            min-width: 20px;
        }
    }
    &__secondary {
        @apply hover:brightness-110;
        span {
            @apply text-sm sm:text-base;
        }
    }
}
</style>
