<script lang="ts" setup>
import type { PropType } from 'vue'
import { isEqual } from 'date-fns'

import type { Models } from '~/types/models'
import { useDateFunctions } from '~/composables/DateFunctions'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { defaultImages, variants } = useVariantsDictionary()

const { getDateDifference } = useDateFunctions()
const config = useRuntimeConfig()
const props = defineProps({
    info: {
        type: Object as PropType<Models.Discount>,
        required: true,
    },
    lazyImage: Boolean,
    expire: {
        type: Boolean,
        default: false,
    },
    noAuth: {
        type: Boolean,
        default: false,
    },
})

const Route = useRoute()

const { $lang, $openers } = useNuxtApp()

const variant = variants[props.info.type]

const defaultImage = defaultImages[props.info.type]

const { savePost, saved } = usePostActions()

saved.value = props.info.saved || false

const fullVersion = computed(() => {
    return (
        Route.path === '/' ||
        Route.path === '/' + $lang.routes.commented ||
        Route.path === '/' + $lang.routes.news
    )
})

const createdAt = new Date(props.info.created_at)

const updatedAt = new Date(props.info.updated_at || 0)

const areTimestampsEqual = isEqual(createdAt, updatedAt)

const isCoupon = props.info.type === 'coupon'
const isOffer = props.info.type === 'offer'
const $config = useRuntimeConfig()
const inStoreRoute = computed(() => {
    return Route.path.includes(`/${props.info.store?.slug}`)
})

const openCoupon = () => {
    if (props.info.is_admin || props.info.without_detail) {
        $openers.openCoupon(props.info.slug, props.info.without_detail ? '' : props.info.store?.slug || '')
    } else {
        $openers.openCoupon(props.info.slug)
    }
}
const openOffer = () => {
    $openers.openOffer(props.info.id)
}

const showNewPrice = computed(() => {
    return (
        (isOffer && !!props.info.new_price) ||
        (isCoupon &&
            (((props.info.discount_type === 'precio' || props.info.discount_type === 'porcentaje') &&
                props.info.discount_value !== '0') ||
                props.info.discount_type === 'envío gratis'))
    )
})
const showOldPrice = computed(() => {
    return isOffer && !!props.info.old_price && !!props.info.new_price && props.info.new_price !== '0'
})

const showPercent = computed(() => {
    return (
        !!props.info.old_price &&
        !!props.info.new_price &&
        !!props.info.discount_percent &&
        props.info.new_price !== '0'
    )
})

const newCouponPrice = computed(() => {
    return props.info.discount_type === 'envío gratis'
        ? $lang.components.cardMainGlobalFeatured.free_ship
        : props.info.discount_type === 'precio'
          ? $lang.symbol + props.info.discount_value + ' OFF'
          : props.info.discount_value === '100'
            ? $lang.components.cardMainGlobalFeatured.free
            : props.info.discount_value + '% OFF'
})

const newOfferPrice = computed(() => {
    return props.info.new_price === '0' ? 'GRATIS' : $lang.symbol + props.info.new_price
})

const newPrice = computed(() => {
    return (isOffer && newOfferPrice.value) || (isCoupon && newCouponPrice.value) || ''
})

const tagClass = computed(() => {
    return {
        offer: props.info.type === 'offer',
        coupon: props.info.type === 'coupon',
    }
})
</script>

<template>
    <article
        class="card-main-global-featured"
        :class="fullVersion ? (noAuth ? 'featured-full no-auth' : 'featured-full') : 'featured-short'"
    >
        <header
            v-if="info.user && fullVersion && $config.public.variant === 'megadescuentos'"
            class="card-main-global-featured__user"
        >
            <MiscUserPic :user="info.user" class="h-6 w-6 lg:h-7 lg:w-7" />

            <div class="card-main-global-featured__user-info">
                <p class="card-main-global-featured__user-name">
                    {{ $lang.components.cardMainGlobalFeatured.published_by }}
                    <span>{{ info.user.username }}</span>
                </p>
                <p class="card-main-global-featured__user-timestamp">
                    {{ $lang.components.cardMainGlobalFeatured.ago }}
                    {{ getDateDifference(areTimestampsEqual ? createdAt : updatedAt) }}
                </p>
            </div>
        </header>
        <div
            v-if="!fullVersion && info.temperature_sum && $config.public.variant === 'megadescuentos'"
            class="card-main-global-featured__heat"
        >
            <DWidgetHeat
                class="card-main-global-featured__heat-counter"
                text-style="text-xs"
                icon-style="w-3 h-3"
                :count="info.temperature_sum"
            />
        </div>
        <div class="px-2">
            <button
                v-if="isCoupon && info.is_admin && inStoreRoute"
                type="button"
                class="card-main-global-featured__data"
                @click="openCoupon"
            >
                <span v-if="showNewPrice" class="number">{{ newCouponPrice }}</span>
                <span
                    class="type"
                    :class="!showNewPrice ? 'text-xl sm:text-3xl' : 'bg-site-primary bg-opacity-10 text-sm'"
                    >{{ $lang.components.cardMainCoupons.coupon }}</span
                >
            </button>
            <button
                v-else-if="info.is_admin || info.without_detail"
                type="button"
                class="card-main-global-featured__image"
                @click=";(isCoupon && openCoupon()) || (isOffer && openOffer())"
            >
                <client-only>
                    <img
                        :src="
                            info.featured_image ||
                            (info.store && info.store.image && info.store.image.url) ||
                            ''
                        "
                        :alt="info.title"
                        :title="info.title"
                        width="174px"
                        height="80px"
                        sizes="(max-width: 1023px) 174px, (min-width: 1024px) 190px"
                        loading="lazy"
                        :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                    />
                </client-only>
            </button>
            <NuxtLink
                no-prefetch
                v-else
                class="card-main-global-featured__image"
                :to="{
                    name: isOffer ? $lang.routes.offers + '-slug' : $lang.routes.coupons + '-slug',
                    params: { slug: info.slug },
                }"
            >
                <img
                    :src="
                        info.featured_image || (info.store && info.store.image && info.store.image.url) || ''
                    "
                    :alt="info.title"
                    :title="info.title"
                    width="174px"
                    height="80px"
                    sizes="(max-width: 1023px) 174px, (min-width: 1024px) 190px"
                    loading="lazy"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                />
            </NuxtLink>
        </div>
        <div class="card-main-global-featured__details">
            <p class="card-main-global-featured__tag" :class="tagClass">
                {{
                    info.type === 'offer'
                        ? $lang.components.cardMainGlobalFeatured.offer_big
                        : $lang.components.cardMainGlobalFeatured.coupon_big
                }}
            </p>
            <div
                v-if="info.is_admin || info.without_detail"
                class="card-main-global-featured__title"
                @click=";(isCoupon && openCoupon()) || (isOffer && openOffer())"
            >
                <p>{{ info.title }}</p>
            </div>
            <NuxtLink
                no-prefetch
                v-else
                class="card-main-global-featured__title"
                :to="`${variant.path}/${info.slug}`"
                rel="follow"
            >
                <p>{{ info.title }}</p>
            </NuxtLink>
            <div class="padding-grow"></div>
            <div v-if="showNewPrice" class="card-main-global-featured__prices-and-time">
                <p class="new-price" :class="{ 'text-site-primary': info.is_admin }">
                    {{ newPrice }}
                </p>
                <p v-if="showOldPrice" class="old-price">{{ $lang.symbol + info.old_price }}</p>
                <p v-if="showPercent" class="discount-percent">{{ info.discount_percent + '%' }}</p>
            </div>
            <div v-if="fullVersion" class="card-main-global-featured__store-and-save">
                <p v-if="info.store" class="card-main-global-featured__store-link">
                    {{ $lang.components.cardMainGlobalFeatured.more }}

                    <NuxtLink no-prefetch :to="`/${$lang.routes.brands}/${info.store.slug}`">{{
                        info.store.name
                    }}</NuxtLink>
                </p>
                <button
                    v-if="
                        !info.is_admin && !info.without_detail && $config.public.variant === 'megadescuentos'
                    "
                    class="card-main-global-featured__save-button"
                    type="button"
                    @click="() => savePost(info)"
                >
                    <img
                        :src="$assets[saved ? 'primary' : 'gray'].bookmark"
                        class="user-profile_comments-and-bookmark_icon"
                        alt="agregar a favoritos"
                        width="10px"
                        height="10px"
                    />
                </button>
            </div>
        </div>
        <footer
            v-if="fullVersion && $config.public.variant === 'megadescuentos'"
            class="card-main-global-featured__footer"
        >
            <DWidgetHeatVote
                v-if="!info.is_admin"
                :voted="!!info.voted && info.voted.type"
                :count="info.temperature_sum"
                :voteable-type="info.type"
                :voteable-id="info.id"
                :show-expire="false"
                :expire="expire"
                scale="small"
            />
            <NuxtLink
                no-prefetch
                v-if="!info.without_detail && !info.is_admin"
                :to="`${variant.path}/${info.slug}#bloque-de-comentarios`"
                rel="follow"
                class="card-main-global-featured__comment-links"
            >
                <img :src="$assets.gray.comment" alt="comentarios" width="10px" height="10px" />
                <span class="self-center">{{
                    typeof info.comments_count === 'number' ? info.comments_count : 0
                }}</span>
            </NuxtLink>
        </footer>
    </article>
</template>

<style lang="postcss" scoped>
.card-main-global-featured {
    @apply relative flex h-full w-48 flex-col items-stretch overflow-hidden rounded-xl border bg-white pt-2 lg:w-52;
    &.featured-full {
        @apply h-[353px] lg:h-[401px];
        &.no-auth {
            @apply h-[251px] lg:h-[295px];
        }
    }
    &.featured-short {
        @apply h-[243px] lg:h-[287px];
    }
    &__heat {
        @apply absolute right-0 top-0 rounded-bl-2xl border-b border-l bg-white py-1 pl-2 pr-3;
    }
    &__user {
        @apply mb-2 flex items-center space-x-2 border-b px-2 pb-2;
    }
    &__user-image {
        @apply block;
    }
    &__user-info {
        @apply font-medium lg:space-y-1;
    }
    &__user-name {
        @apply text-xs leading-3;
        span {
            @apply text-sm font-semibold leading-3 text-black;
        }
    }
    &__user-timestamp {
        @apply text-[10px] font-semibold leading-3 text-gray-800;
    }
    &__data {
        @apply col-span-4 row-span-2 row-start-1 mx-auto flex h-20 flex-col items-center justify-center space-y-0.5 p-2 text-center text-gray-800 md:col-span-2 md:row-span-3 lg:h-28 lg:space-y-2;

        .number {
            @apply text-xl font-semibold leading-8 lg:text-3xl lg:leading-9;
        }

        .type {
            @apply px-1 py-0.5 font-semibold;
        }
    }
    &__image {
        @apply mb-2 block h-20 w-fit overflow-hidden lg:h-28;
        img {
            @apply mx-auto block h-20 w-[174px] object-contain object-center lg:h-28 lg:w-[190px];
        }
    }
    &__details {
        @apply mb-2 flex flex-grow flex-col border-t px-2 pt-2;
        .padding-grow {
            @apply flex-grow;
        }
    }
    &__tag {
        @apply mb-2 max-w-max rounded-full px-2 py-0.5 text-[10px] font-medium leading-3;
        &.offer {
            @apply bg-site-secondary text-site-secondary-text-contrast;
        }
        &.coupon {
            @apply bg-site-primary text-site-primary-text-contrast;
        }
    }
    &__title {
        @apply mb-2 line-clamp-3 cursor-pointer gap-2 font-normal leading-5 text-gray-800 lg:text-lg lg:leading-6 lg:hover:underline;
    }
    &__prices-and-time {
        @apply flex items-baseline space-x-2;
        .new-price {
            @apply text-lg font-semibold xs:text-xl;
        }

        .old-price {
            @apply text-sm text-gray-800 line-through xs:text-base;
        }

        .discount-percent {
            @apply text-sm font-semibold text-site-primary xs:text-base;
        }
    }
    &__store-and-save {
        @apply flex h-4 items-center justify-end;
    }
    &__store-link {
        @apply mr-auto text-xs font-normal text-gray-800;
        a {
            @apply font-semibold text-black hover:underline;
        }
    }
    &__save-button {
        @apply block h-4 w-4;
        img {
            @apply h-full w-full object-contain;
        }
    }
    &__footer {
        @apply flex h-[41px] items-center justify-between border-t px-2 py-2;
    }
    &__comment-links {
        @apply flex items-center space-x-1 text-xs text-gray-800;
        img {
            @apply block h-4 w-4 object-contain;
        }
    }
}
</style>
