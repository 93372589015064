<template>
    <div class="btn-discount-component" :class="containerClass">
        <button
            v-if="showCode"
            type="button"
            class="btn-discount-component-code"
            :class="discountCodeClass"
            @click="() => isSupported && !info.isOnlyToView && copyText()"
        >
            <label
                v-show="copied"
                :for="info.slug + '-code-input'"
                class="btn-discount-component-coupon-copied"
                >{{ $lang.components.btnDiscount.copied }}</label
            >
            <p aria-label="code" class="text-black" :class="info.code ? textSize : 'text-sm'">
                {{ code }}
            </p>
            <img v-if="info.code" :src="discountCodeIcon" alt="icon" />
        </button>
        <template v-if="showButton && !(info.isAdmin && info.showCode)">
            <a
                v-if="info.discount && info.discount.with_digidip && !info.isAdmin"
                target="blank"
                rel="nofollow, noopener, noreferrer"
                :href="info.discount.url || ''"
                class="btn-discount-component-opener"
                :class="discountBtnClass"
            >
                <img v-if="info.mode === 'lottery'" :src="$assets.white.logout" alt="icon" />
                <span v-if="/^(coupon|offer|lottery)$/.test(info.mode)">{{ buttonText }}</span>
                <img
                    v-if="info.mode === 'offer-code'"
                    class="arrow"
                    :src="$assets.white.arrowRight"
                    alt="icon"
                />
            </a>
            <button
                v-else
                type="button"
                class="btn-discount-component-opener"
                :class="discountBtnClass"
                @click="buttonHandler"
            >
                <img v-if="info.mode === 'lottery'" :src="$assets.white.logout" alt="icon" />
                <span v-if="/^(coupon|offer|lottery)$/.test(info.mode)">{{ buttonText }}</span>
                <img
                    v-if="info.mode === 'offer-code'"
                    class="arrow"
                    :src="$assets.white.arrowRight"
                    alt="icon"
                />
                <img
                    v-if="info.mode === 'coupon' && info.isAdmin"
                    class="bend"
                    :src="$assets.primary.buttonBendCoupon"
                    alt="icon"
                />
            </button>
        </template>
        <template v-if="showNLink">
            <a
                v-if="info.discount && info.discount.with_digidip"
                rel="nofollow, noopener, noreferrer"
                :href="info.discount.url || ''"
                class="btn-discount-component-no-link"
                :class="discountLinkClass"
            >
                {{ buttonText }}
            </a>
            <NuxtLink
                no-prefetch
                v-else
                rel="follow"
                :to="noLinkOfferUrl"
                class="btn-discount-component-no-link"
                :class="discountLinkClass"
            >
                {{ buttonText }}
            </NuxtLink>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue'
import { useClipboard } from '@vueuse/core'
import type { Models } from '~/types/models'

const props = defineProps({
    info: {
        type: Object as PropType<{
            inResume?: boolean
            inCard?: boolean
            isAdmin?: boolean
            showCode?: boolean
            slug?: string
            code: string
            mode: 'offer' | 'offer-code' | 'coupon' | 'no-link-offer' | 'lottery' | 'no-link-coupon' | ''
            openLottery?: () => any
            openCoupon?: () => any
            openOffer?: () => any
            isOnlyToView?: boolean
            discount: Models.Discount
        }>,
        required: true,
    },
})
const { $assets, $lang } = useNuxtApp()

const textSize = computed(() => {
    switch (true) {
        case props.info.code.length > 16:
            return 'large' + (props.info.inResume ? ' small' : '')
        case props.info.code.length > 12:
            return 'medium' + (props.info.inResume ? ' small' : '')
        case props.info.code.length > 6:
            return 'short' + (props.info.inResume ? ' small' : '')
        default:
            return 'normal' + (props.info.inResume ? ' small' : '')
    }
})

const buttonText = computed(() => {
    return props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon'
        ? $lang.components.btnDiscount.see_coupon
        : $lang.components.btnDiscount.see_offer
})

const code = computed(() => {
    return props.info.code || $lang.components.btnDiscount.no_coupon_required
})

const showCode = computed(() => {
    return (
        (!props.info.isAdmin &&
            (props.info.mode === 'offer-code' ||
                ((props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon') &&
                    props.info.showCode))) ||
        (props.info.isAdmin && props.info.showCode) ||
        (props.info.mode === 'coupon' && props.info.isOnlyToView)
    )
})

const showButton = computed(() => {
    return /^(offer-code|offer|lottery|coupon)$/.test(props.info.mode)
})

const showNLink = computed(() => {
    return (
        (props.info.mode === 'no-link-coupon' && !props.info.showCode) || props.info.mode === 'no-link-offer'
    )
})

const buttonHandler = computed(() => {
    return props.info.isOnlyToView
        ? () => null
        : props.info.mode === 'coupon'
          ? () => {
                copyText()
                props.info.openCoupon?.()
            }
          : props.info.mode === 'offer' || props.info.mode === 'offer-code'
            ? props.info.openOffer
            : props.info.mode === 'lottery'
              ? props.info.openLottery
              : () => null
})

const noLinkOfferUrl = computed(() => {
    return props.info.isOnlyToView
        ? '#'
        : props.info.mode === 'no-link-offer'
          ? `/${$lang.routes.offers}/${props.info.slug}`
          : props.info.mode === 'no-link-coupon'
            ? `/${$lang.routes.coupons}/${props.info.slug}`
            : ''
})

const containerClass = computed(() => {
    return {
        'small-button': props.info.inResume,
        'is-card': props.info.inCard,
        'is-dual': !props.info.isAdmin && (props.info.mode === 'offer-code' || props.info.mode === 'coupon'),
        'is-coupon': props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon',
    }
})

const discountCodeClass = computed(() => {
    return {
        'is-secondary-outline': props.info.mode === 'offer-code',
        'is-primary-outline': /^(coupon|no-link-coupon)$/.test(props.info.mode),
        'with-link': showCode && showButton && !(props.info.isAdmin && props.info.showCode),
    }
})

const discountBtnClass = computed(() => {
    return {
        'is-admin-coupon': props.info.mode === 'coupon' && props.info.isAdmin,
        'is-primary': /^(coupon|lottery)$/.test(props.info.mode),
        'is-secondary': /^(offer|offer-code)$/.test(props.info.mode),
        'is-dual': !props.info.isAdmin && (props.info.mode === 'offer-code' || props.info.mode === 'coupon'),
        'is-coupon': props.info.mode === 'coupon',
        'is-offer': props.info.mode === 'offer',
        'is-lottery': props.info.mode === 'lottery',
    }
})

const discountLinkClass = computed(() => {
    return {
        'is-secondary-outline': props.info.mode === 'no-link-offer',
        'is-primary-outline is-coupon': props.info.mode === 'no-link-coupon',
    }
})

const discountCodeIcon = computed(() => {
    return $assets[
        props.info.mode === 'coupon' || props.info.mode === 'no-link-coupon' ? 'primary' : 'secondary'
    ].copyCode
})

const { copy: copyText, copied, isSupported } = useClipboard({ source: code })
</script>
