<template>
    <ul class="widget-stores-gallery">
        <li v-for="item in data" :key="item.id">
            <NuxtLink
                no-prefetch
                :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${item.slug}`"
                rel="follow"
            >
                <picture>
                    <img
                        v-if="item.image"
                        :src="item.image.url"
                        :alt="item.name"
                        :title="item.name"
                        width="10px"
                        height="10px"
                        loading="lazy"
                        :onerror="`this.onerror=null;this.src='${defaultImages.store}'`"
                    />
                </picture>
            </NuxtLink>
        </li>
    </ul>
</template>

<script lang="ts">
import type { Models } from '~/types/models'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

export default defineComponent({
    name: 'CardImagesGallery',
    props: {
        data: {
            type: Array as PropType<Models.Store[]>,
        },
    },
    data() {
        const { defaultImages } = useVariantsDictionary()
        return {
            defaultImages,
        }
    },
})
</script>

<style lang="postcss" scoped>
.widget-stores-gallery {
    @apply grid grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6;
    li {
        @apply h-20 w-full overflow-hidden rounded-xl border bg-white hover:border-gray-300;
        a {
            img {
                @apply h-full w-full object-contain p-2;
            }
        }
    }
}
</style>
