import type { RouterConfig } from '@nuxt/schema'
import { variantConfig } from '~/config/variant'

const LANG = variantConfig.siteLangs[variantConfig.siteVariant]

export default <RouterConfig>{
    routes: (_) => {
        return _.map((r) => {
            if (r.name === 'dashboard') {
                return {
                    ...r,
                    children: [
                        {
                            path: `/${LANG.routes.dashboard}`,
                            component: () => import('~/views/dashboard/index/index.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/${LANG.routes.activity}`,
                            component: () => import('~/views/dashboard/index/actividad.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/${LANG.routes.settings}`,
                            component: () => import('~/views/dashboard/index/ajustes.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/${LANG.routes.chats}`,
                            component: () => import('~/views/dashboard/index/chats.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/${LANG.routes.discounts}`,
                            component: () => import('~/views/dashboard/index/descuentos.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/${LANG.routes.saved}`,
                            component: () => import('~/views/dashboard/index/guardados.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/${LANG.routes.coins}`,
                            component: () => import('~/views/dashboard/index/monedas.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/${LANG.routes.awards}`,
                            component: () => import('~/views/dashboard/index/premios.vue'),
                        },
                    ],
                }
            }

            if (r.name === 'dashboard-user') {
                return {
                    ...r,
                    children: [
                        {
                            path: `/${LANG.routes.dashboard}/:user`,
                            component: () => import('~/views/dashboard/[user]/index.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/:user/${LANG.routes.activity}`,
                            component: () => import('~/views/dashboard/[user]/actividad.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/:user/${LANG.routes.discounts}`,
                            component: () => import('~/views/dashboard/[user]/descuentos.vue'),
                        },
                        {
                            path: `/${LANG.routes.dashboard}/:user/${LANG.routes.chats}`,
                            component: () => import('~/views/dashboard/[user]/chats.vue'),
                        },
                    ],
                }
            }

            if (r.path === '/sitemap') {
                return {
                    ...r,
                    children: [
                        {
                            path: `/sitemap`,
                            component: () => import('~/views/sitemap/index/index.vue'),
                        },
                        {
                            path: `/sitemap/tiendas`,
                            component: () => import('~/views/sitemap/index/tiendas.vue'),
                        },
                        {
                            path: `/sitemap/promociones`,
                            component: () => import('~/views/sitemap/index/promociones/index.vue'),
                        },
                        {
                            name: 'sitemap-promociones-slug',
                            path: `/sitemap/promociones/:slug`,
                            component: () => import('~/views/sitemap/index/promociones/[slug].vue'),
                        },
                    ],
                }
            }
            return r
        })
    },
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        if (to.path !== from.path) {
            return { top: 0 }
        }
    },
}
