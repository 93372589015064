<template>
    <div v-if="showNewPrice || showOldPrice || showPercent" class="micro-price" :class="mode">
        <p v-if="showNewPrice" class="new-price">{{ newPrice }}</p>

        <p v-if="showOldPrice" class="old-price">{{ $lang.symbol + info.old_price }}</p>

        <p v-if="showPercent" class="discount-percent">{{ info.discount_percent + '%' }}</p>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue'
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'MicroPrice',
    props: {
        info: {
            type: Object as PropType<Models.Discount>,
            required: true,
        },
        isOffer: Boolean,
        isCoupon: Boolean,
        mode: String,
    },
    computed: {
        showOldPrice(): boolean {
            return (
                this.isOffer && !!this.info.old_price && !!this.info.new_price && this.info.new_price !== '0'
            )
        },
        showNewPrice(): boolean {
            return (
                (this.isOffer && !!this.info.new_price) ||
                (this.isCoupon &&
                    (((this.info.discount_type === 'precio' || this.info.discount_type === 'porcentaje') &&
                        this.info.discount_value !== '0') ||
                        this.info.discount_type === 'envío gratis'))
            )
        },
        newOfferPrice(): string {
            return this.info.new_price === '0'
                ? this.$lang.components.cardMainGlobal.free
                : this.$lang.symbol + this.info.new_price
        },
        newCouponPrice(): string {
            return this.info.discount_type === 'envío gratis'
                ? this.$lang.components.cardMainGlobal.free_ship
                : this.info.discount_type === 'precio'
                  ? this.$lang.symbol + this.info.discount_value + ' OFF'
                  : this.info.discount_value === '100'
                    ? this.$lang.components.cardMainGlobal.free
                    : this.info.discount_value + '% OFF'
        },
        newPrice(): string {
            return (this.isOffer && this.newOfferPrice) || (this.isCoupon && this.newCouponPrice) || ''
        },
        showPercent(): boolean {
            return (
                !!this.info.old_price &&
                !!this.info.new_price &&
                !!this.info.discount_percent &&
                this.info.new_price !== '0'
            )
        },
    },
})
</script>

<style lang="postcss" scoped>
.micro-price {
    @apply flex flex-wrap items-baseline gap-x-1.5;
    .new-price {
        @apply text-lg font-semibold xs:text-xl;
    }

    .old-price {
        @apply text-sm text-gray-800 line-through xs:text-base;
    }

    .discount-percent {
        @apply text-sm font-semibold text-site-primary xs:text-base;
    }

    &.large {
        .new-price {
            @apply text-2xl;
        }

        .old-price {
            @apply text-xl;
        }

        .discount-percent {
            @apply text-xl;
        }
    }
}
</style>
