<script lang="ts" setup>
import {
    addMonths,
    format,
    formatDistanceToNowStrict,
    isBefore,
    isToday,
    isTomorrow,
    parseISO,
    subDays,
} from 'date-fns'
import { es as locale } from 'date-fns/locale'
import { formatInTimeZone } from 'date-fns-tz'
import { useAuthStore } from '~/store/auth'
import type { Api, ServicesQueryParameters } from '~~/global'
import type { Models } from '~/types/models'
import { removeXSS, replacePlaceholders, textShortener } from '~/util/textFunction'
import encodeUrl from 'encodeurl'
import { useRootStore } from '~/store/root'
import type { DropdownOptionType } from '../components/dropdowns/Options.vue'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'

const { defaultImages, variants } = useVariantsDictionary()
const { $lang, $openers, $bp, $assets } = useNuxtApp()
const Route = useRoute()
const AuthStore = useAuthStore()
const showCode = ref(false)
const RootStore = useRootStore()

const {
    public: { origin, siteName, lang },
} = useRuntimeConfig()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data, error } = await useAsyncData('discount-details-index', async () => {
    const routeEndpoint =
        Route.name === $lang.routes.offers + '-slug'
            ? endpoints.pages.offer.path
            : Route.name === $lang.routes.coupons + '-slug'
              ? endpoints.pages.coupon.path
              : false

    if (!routeEndpoint) {
        throw createError({
            statusCode: 404,
            message: 'Resource not found',
        })
    }

    const endpoint = routeEndpoint.replace('_SLUG_', Route.params.slug as string)

    const response = await $fetch<Api.Responses.Pages.DiscountDetails | Api.Responses.ResourceNotFound>(
        endpoint,
        {
            headers: buildHeaders(AuthStore.SessionToken),
            baseURL,
            method: 'GET',
        },
    ).catch<Api.Responses.ResourceNotFound>((e) => {
        return e.data || false
    })

    return response
})

if (!data.value || data.value.error || data.value.feedback !== 'data_success') {
    throw createError({
        statusCode: 404,
        message: 'Resource not found',
    })
}

const responseData = data.value.data

const discountData = responseData.coupon || responseData.offer

const { currentDate } = useDateFunctions()

const currentDates = currentDate()

const parsedDates = {
    memberSince: discountData.user?.created_at && parseISO(discountData.user.created_at),
    expiredAt: discountData.expired_at && parseISO(discountData.expired_at),
    updatedAt: discountData.updated_at && parseISO(discountData.updated_at),
    createdAt: parseISO(discountData.created_at),
    startAt: discountData.start_at && parseISO(discountData.start_at),
}

const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':year', String(currentDates.year) || ''],
        [':title', textShortener(discountData.title || '', 90)],
        [':description', textShortener(discountData.description || '', 165)],
        [':site', siteName || ''],
    ],
    [discountData.seo?.title || '', discountData.seo?.description || ''],
)

const addNoRobots = computed(() => {
    return !!parsedDates.expiredAt && isBefore(parsedDates.expiredAt, subDays(new Date(), 7))
})
useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    ogImage: discountData.featured_image || '',
    ogImageSecureUrl: discountData.featured_image || '',
    twitterImage: discountData.featured_image || '',
    twitterCard: 'summary_large_image',
    ...(addNoRobots.value
        ? {
              robots: 'noindex, nofollow',
          }
        : {}),
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

/* jsonld(): any {
        return {
            '@context': 'http://schema.org',
            '@graph': [
                {
                    '@context': 'https://schema.org',
                    '@type': 'Article',
                    author: {
                        '@type': 'Person',
                        name: discountData.user?.name,
                        url: `https://www.megadescuentos.com/dashboard/${discountData.user?.id}`,
                    },
                    datePublished: discountData.created_at,
                    headline: discountData.title,
                    image: {
                        '@type': 'ImageObject',
                        url: discountData.featured_image,
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: 'MEGAdescuentos',
                    },
                    dateModified: discountData.updated_at,
                    description: discountData.description,
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': `https://www.megadescuentos.com/${
                            this.isOffer ? 'ofertas' : this.isCoupon ? 'cupones' : 'sorteos'
                        }/${discountData.slug}`,
                    },
                },
                {
                    '@context': 'https://schema.org',
                    '@type': 'BreadcrumbList',
                    itemListElement: [
                        ...this.breadcrumbs
                            .filter((_, index) => this.breadcrumbs.length !== index + 1)
                            .map((value, index) => {
                                return {
                                    '@type': 'ListItem',
                                    position: index + 1,
                                    item: `https://www.megadescuentos.com${value.path}`,
                                    name: value.title,
                                }
                            }),
                    ],
                },
            ],
        }
    } */

const { savePost, saved, reportPost, expirePost, createNewComment, creatingNewComment } = usePostActions()

saved.value = discountData.saved || false

const isCoupon = !!responseData.coupon

const isOffer = !!responseData.offer

const isExclusive = discountData.is_exclusive

const isVerified = discountData.is_verified

const type = discountData.type

const variant = variants[type]

const dates = {
    endSoon: '',

    updatedDate:
        parsedDates.updatedAt &&
        formatInTimeZone(parsedDates.updatedAt, $lang.timeZone, 'dd/MM/yyyy', { locale }),

    endingDate:
        parsedDates.expiredAt &&
        formatInTimeZone(parsedDates.expiredAt, $lang.timeZone, 'dd/MM/yyyy HH:mm', {
            locale,
        }),

    startingDate:
        parsedDates.startAt &&
        parsedDates.startAt > new Date() &&
        formatInTimeZone(parsedDates.startAt, $lang.timeZone, 'dd/MM/yyyy HH:mm', { locale }),

    createTimeAgo: formatDistanceToNowStrict(parsedDates.createdAt, { locale }),

    updateTimeAgo: parsedDates.updatedAt && formatDistanceToNowStrict(parsedDates.updatedAt, { locale }),

    isExpired:
        !discountData.never_expires &&
        ((!!parsedDates.expiredAt && isBefore(parsedDates.expiredAt, new Date())) ||
            (!!parsedDates.updatedAt && isBefore(addMonths(parsedDates.updatedAt, 6), new Date()))),

    memberSince: parsedDates.memberSince && format(parsedDates.memberSince, 'PPP', { locale }),

    endToday: parsedDates.expiredAt && isToday(parsedDates.expiredAt),

    endTomorrow: parsedDates.expiredAt && isTomorrow(parsedDates.expiredAt),

    hasOneMonthOld: isBefore(addMonths(parsedDates.updatedAt || parsedDates.createdAt, 1), new Date()),
}

const showHeaderMessage =
    (!isExclusive || !isVerified) && (isCoupon || isOffer) && dates.hasOneMonthOld && !dates.isExpired

const existHeaderMessage = !!discountData.header_messages && !!discountData.header_messages[0]

const headerMessage = showHeaderMessage
    ? ({
          colour: 'neutral',
          icon: 'fa fa-clock-o',
          content: isCoupon
              ? $lang.views.discountDetails.coupon_may_not_work
              : $lang.views.discountDetails.offer_may_not_work,
      } as Models.HeaderMessage)
    : existHeaderMessage
      ? discountData.header_messages[0]
      : false

const breadcrumbs = computed(() => {
    const hierarchy = [] as { path: string; title: string }[]

    if (responseData.breadcrumbs) {
        hierarchy.push({
            path: `/${$lang.routes.categories}/` + responseData.breadcrumbs.slug,
            title: responseData.breadcrumbs.name,
        })
        if (responseData.breadcrumbs.parent) {
            hierarchy.push({
                path: `/${$lang.routes.categories}/` + responseData.breadcrumbs.parent.slug,
                title: responseData.breadcrumbs.parent.name,
            })
            if (responseData.breadcrumbs.parent.parent) {
                hierarchy.push({
                    path: `/${$lang.routes.categories}/` + responseData.breadcrumbs.parent.parent.slug,
                    title: responseData.breadcrumbs.parent.parent.name,
                })
            }
        }
    }
    return [
        {
            path: '/',
            title: $lang.views.discountDetails.home,
        },
        ...(hierarchy.length
            ? hierarchy.reverse()
            : [
                  {
                      path: variant.path,
                      title: variant.wordPlural.charAt(0).toUpperCase() + variant.wordPlural.slice(1),
                  },
              ]),
        {
            title: discountData.title || '',
        },
    ]
})
const discountDescription = computed(() => {
    return discountData.description?.replace(/<[^>]+>/g, '')
})
// @ts-ignore
useJsonld({
    '@context': 'http://schema.org',
    '@graph': [
        {
            '@type': 'Article',
            author: {
                '@type': 'Person',
                name: discountData.user?.name,
                url: `${origin}/dashboard/${discountData.user?.id}`,
            },
            datePublished: discountData.created_at,
            headline: discountData.title,
            image: {
                '@type': 'ImageObject',
                url: discountData.featured_image,
            },
            publisher: {
                '@type': 'Organization',
                name: $lang.brand,
            },
            dateModified: discountData.updated_at,
            description: discountDescription.value,
            mainEntityOfPage: {
                '@type': 'WebPage',
                '@id': `${origin}/${
                    isOffer ? 'ofertas' : isCoupon ? 'cupones' : 'sorteos'
                }/${discountData.slug}`,
            },
            inLanguage: lang.code,
        },
        {
            '@type': 'BreadcrumbList',
            itemListElement: [
                ...breadcrumbs.value
                    .filter((_, index, arg) => arg.length !== index + 1)
                    .map((value, index) => {
                        return {
                            '@type': 'ListItem',
                            position: index + 1,
                            item: `${origin}${value.path}`,
                            name: value.title,
                        }
                    }),
            ],
        },
    ],
})

const openCoupon = () => {
    showCode.value = true
    $openers.openCoupon(discountData.slug)
}
const openOffer = () => {
    $openers.openOffer(discountData.id)
}

const defaultImage = defaultImages[discountData.type]

const showFloatingResume = computed(() => {
    return (
        /^(ofertas|cupones)-slug$/.test(Route.name as string) &&
        /^(coupon|offer|offer-code)$/.test(buttonMode.value || '')
    )
})

const contentDescription = computed(() => {
    return removeXSS(discountData.description) || ''
})
const stores = computed(() => {
    return RootStore.layoutData?.data.sidebar.stores || null
})
const buttonMode = computed(() => {
    if (isOffer) {
        if (discountData.has_url) {
            if (discountData.code) {
                return 'offer-code'
            } else {
                return 'offer'
            }
        } else {
            return 'no-link-offer'
        }
    } else if (isCoupon) {
        if (discountData.has_url) {
            return 'coupon'
        } else {
            return 'no-link-coupon'
        }
    }
    return ''
})
const baseUrl = computed(() => {
    return `${origin}${Route.fullPath}`
})
const whatsapp = computed(() => {
    return encodeUrl(`https://api.whatsapp.com/send?text=${discountData.title}: ${baseUrl.value}`)
})
const twitter = computed(() => {
    return encodeUrl(`https://twitter.com/intent/tweet?text=${discountData.title}: ${baseUrl.value}`)
})
const telegram = computed(() => {
    return encodeUrl(`https://telegram.me/share/url?url=${baseUrl.value}&text=${discountData.title}`)
})
const facebook = computed(() => {
    return encodeUrl(`http://www.facebook.com/sharer.php?u=${baseUrl.value}&t=${discountData.title}`)
})
const isFreeShipping = computed(() => {
    return discountData.discount_type === 'envío gratis' || !!discountData.shipping_free
})
const shippingPrice = computed(() => {
    return isFreeShipping
        ? $lang.views.discountDetails.free_ship
        : !!discountData.shipping_price &&
              discountData.shipping_price !== '0' &&
              $lang.symbol + discountData.shipping_price
})
const timestamp = computed(() => {
    const timestamp = dates.updateTimeAgo || dates.createTimeAgo || ''
    return timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)
})
const areTimestampsEqual = computed(() => {
    return dates.updateTimeAgo === dates.createTimeAgo
})
const timestampCreatedAgo = computed(() => {
    const timestamp = dates.createTimeAgo || ''
    return timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)
})
const timestampUpdatedAgo = computed(() => {
    const timestamp = dates.updateTimeAgo || ''
    return timestamp.split(' ')[0] + timestamp.split(' ')[1]?.charAt(0)
})
const anyInformationExists = computed(() => {
    return (
        !!dates.endSoon ||
        !!dates.endToday ||
        !!dates.endTomorrow ||
        !!isExclusive ||
        !!isVerified ||
        !!dates.endingDate ||
        !!dates.startingDate ||
        !!discountData.is_local
    )
})

const displayedItemFlags = reactive({
    showCommentMenu: false,
    showDiscountMenu: false,
})

const activeFilter = ref(0)

const nextPageQuery = reactive({
    commentable_type: variant.variant,
    commentable_id: discountData.id,
    per_page: 15,
    page: 1,
    order_by: 'most-liked',
}) as ServicesQueryParameters.GetComments

const createReport = async (reason: Parameters<typeof reportPost>[0]['reason']) => {
    try {
        const reportResult = await reportPost({
            reason,
            reportable_type: variant.variant,
            reportable_id: discountData.id,
        })

        if (!reportResult) {
            return
        }
        if (reportResult.feedback === 'data_success') {
            RootStore.setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.views.discountDetails.okay,
                text: $lang.views.discountDetails.report_success,
                type: 'success',
            })
        } else if (
            AuthStore.IsAuth &&
            AuthStore.UserData?.accepted_conditions_at &&
            AuthStore.UserData?.verified_email
        ) {
            RootStore.setSiteNotification({
                duration: 5000,
                dismissButtonText: $lang.views.discountDetails.close,
                text: $lang.views.discountDetails.report_error,
                type: 'error',
            })
        }
    } catch (e) {}
}
const categoriesCollection = computed(() => {
    let result = [] as Models.Category[]

    if (isOffer) {
        if (responseData.offer.category) {
            result = [responseData.offer.category]
        }
        result = [...result, ...(responseData.offer.subcategories || [])]
    } else if (isCoupon) {
        if (responseData.coupon.category) {
            result = [responseData.coupon.category]
        }
        result = [...result, ...(responseData.coupon.subcategories || [])]
    }

    return result
})
const config = useRuntimeConfig()
const dropdownOptions = computed(() => {
    return {
        commentOptions: [
            {
                title: $lang.views.discountDetails.more_relevant,
                icon: $assets.primary.bookmark,
                description: $lang.views.discountDetails.see_comments_relevant,
                handler: () => {
                    nextPageQuery.order_by = 'most-liked'
                    activeFilter.value = 0
                    loadComments(1)
                },
            },
            {
                title: $lang.views.discountDetails.more_recent,
                description: $lang.views.discountDetails.see_all_comments_more_recent_first,
                icon: $assets.primary.subscribe,
                handler: () => {
                    nextPageQuery.order_by = 'latest'
                    activeFilter.value = 1
                    loadComments(1)
                },
            },
        ],
        discountOptions: [
            ...(config.public.variant === 'megadescuentos'
                ? [
                      {
                          title: $lang.views.discountDetails.save,
                          icon: $assets.primary.bookmark,
                          handler: () => savePost(discountData),
                      },
                  ]
                : []),
            {
                title: $lang.views.discountDetails.report,
                icon: $assets.primary.shield,
                description: $lang.views.discountDetails.why_report,
                subDropdown: true,
                options: [
                    {
                        title: $lang.views.discountDetails.spam_ofensive,
                        icon: $assets.primary.span,
                        handler: () => {
                            createReport('Spam/Ofensivo')
                        },
                    },
                    {
                        title: $lang.views.discountDetails.duplicated,
                        description: $lang.views.discountDetails.offer_is_published,
                        icon: $assets.primary.copyCode,
                        handler: () => {
                            createReport('Duplicado')
                        },
                    },
                    {
                        title: $lang.views.discountDetails.refered_links,
                        icon: $assets.primary.referralLink,
                        handler: () => {
                            createReport('Enlaces referidos')
                        },
                    },
                    {
                        title: $lang.views.discountDetails.expired_offer_or_link_not_working,
                        icon: $assets.primary.offer,
                        handler: async () => {
                            const reportResult = await expirePost({
                                expiredable_type: type as 'offer' | 'coupon',
                                expiredable_id: discountData.id,
                            })
                            if (!reportResult) {
                                return
                            }
                            if (reportResult.feedback === 'data_success') {
                                RootStore.setSiteNotification({
                                    duration: 5000,
                                    dismissButtonText: $lang.views.discountDetails.okay,
                                    text: $lang.views.discountDetails.report_success,
                                    type: 'success',
                                })
                            } else if (
                                AuthStore.IsAuth &&
                                AuthStore.UserData?.accepted_conditions_at &&
                                AuthStore.UserData?.verified_email
                            ) {
                                RootStore.setSiteNotification({
                                    duration: 5000,
                                    dismissButtonText: $lang.views.discountDetails.close,
                                    text: $lang.views.discountDetails.report_error,
                                    type: 'error',
                                })
                            }
                        },
                    },
                    {
                        title: $lang.views.discountDetails.other,
                        icon: $assets.primary.about,
                        handler: () => {
                            createReport('Otro')
                        },
                    },
                ],
            },
        ],
    }
})

const dropdownToggle = (show: boolean, key: 'showCommentMenu' | 'showDiscountMenu') => {
    if (($bp.mb || $bp.xs || $bp.sm) && (key === 'showCommentMenu' || key === 'showDiscountMenu')) {
        RootStore.setSiteDropdown(
            show
                ? ((key === 'showCommentMenu'
                      ? dropdownOptions.value.commentOptions
                      : dropdownOptions.value.discountOptions) as DropdownOptionType[])
                : null,
        )
    } else {
        displayedItemFlags[key] = show
    }
}

const similarDiscounts =
    (isOffer && responseData.similar_offers) || (isCoupon && responseData.similar_coupons) || null

const follow = ref(false)

const scrollToCommentsSection = useScrollToComponent()

const loadingComments = ref(false)
const comments = ref([]) as Ref<Models.Comment[]>
const linkToCommentId = ref(null) as Ref<any>

/* TO_DO: permalink revision */

const createComment = async (html: string) => {
    if (!AuthStore.IsAuth) {
        AuthStore.setAuthModal({
            type: 'sign-in',
        })
        return false
    }
    if (!AuthStore.UserData?.verified_email) {
        AuthStore.setAuthModal({
            type: 'email-verification',
            info: {
                isReminder: true,
                message: `${$lang.plugins.axiosInterceptors.for} ${$lang.plugins.axiosInterceptors.do_comment} ${$lang.plugins.axiosInterceptors.need_verify_account}`,
            },
        })
        return false
    }
    const comment = await createNewComment({
        commentable_id: discountData.id,
        commentable_type: type,
        content: html,
    })
    if (comment) {
        comments.value = [comment.data, ...comments.value]
    }
    return comment
}

const showLoadComments = ref(true)

const loadComments = async (page?: number) => {
    try {
        loadingComments.value = true
        if (page) {
            nextPageQuery.page = page
        }

        const commentsResult = await $fetch<Api.Responses.General.Comments>(endpoints.general.comments.path, {
            headers: buildHeaders(),
            params: nextPageQuery,
            baseURL,
        })

        if (commentsResult.feedback === 'data_success') {
            if (commentsResult.data.meta.current_page === 1) {
                comments.value = [...commentsResult.data.results]
            } else {
                const _comments = linkToCommentId
                    ? commentsResult.data.results.filter((comment: any) => comment.id !== linkToCommentId)
                    : commentsResult.data.results

                comments.value = [...comments.value, ..._comments]
            }

            if (commentsResult.data.meta.current_page < commentsResult.data.meta.last_page) {
                nextPageQuery.page = commentsResult.data.meta.current_page + 1
            }
            showLoadComments.value =
                commentsResult.data.meta.current_page !== commentsResult.data.meta.last_page
        }
    } catch (error) {
        console.log('Error on fetch comments: ', { error })
    }
    loadingComments.value = false
}
</script>

<template>
    <DWidgetHeaderResume
        :info="{
            discount: discountData,
            mode: buttonMode,
        }"
    />
    <div class="discount-details-component">
        <LayoutAlertMessage v-if="headerMessage" :info="headerMessage" />
        <div class="discount-details-component__sub-container">
            <LayoutBreadcrumbs :layers="breadcrumbs" text-color="text-gray-900" />
            <main class="discount-details-component__main">
                <article :class="{ 'opacity-70 grayscale filter': dates.isExpired }" class="offer-details">
                    <div class="offer-details__image-content">
                        <picture
                            class="offer-details__image"
                            @click="
                                () => {
                                    isCoupon ? openCoupon() : isOffer ? openOffer() : () => null
                                }
                            "
                        >
                            <img
                                v-if="discountData.featured_image"
                                :src="discountData.featured_image"
                                :alt="discountData.title"
                                :title="discountData.title"
                                :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                            />
                        </picture>
                        <!-- <button
                            class="offer-details__image-zoomin"
                            type="button"
                            :aria-label="$lang.views.discountDetails.expand"
                            @click="zoomIn"
                        >
                            <i class="offer-details__image-zoomin-icon fas fa-expand fa-lg" />
                            <p>{{ $lang.views.discountDetails.expand }}</p>
                        </button> -->
                        <i
                            class="offer-details__image-external fas fa-external-link fa-md"
                            @click="
                                () => {
                                    isCoupon ? openCoupon() : isOffer ? openOffer() : () => null
                                }
                            "
                        >
                        </i>
                    </div>

                    <div class="offer-details__right">
                        <div class="offer-details__top">
                            <span class="offer-details__dates">
                                <p v-if="timestampCreatedAgo" class="offer-details__dates-created">
                                    <img :src="$assets.gray.clock" alt="icon" />
                                    <span>
                                        {{ $lang.views.discountDetails.published_about }}
                                        {{ timestampCreatedAgo }}
                                    </span>
                                </p>
                                <p
                                    v-if="!areTimestampsEqual && timestampUpdatedAgo"
                                    class="offer-details__dates-updated"
                                >
                                    <span>
                                        ({{ $lang.views.discountDetails.updated_at }}
                                        {{ timestampUpdatedAgo }})
                                    </span>
                                </p>
                            </span>

                            <div v-if="$config.public.variant === 'megadescuentos'" class="discount-dropdown">
                                <button
                                    type="button"
                                    class="discount-dropdown__button"
                                    @click="
                                        () =>
                                            dropdownToggle(
                                                !displayedItemFlags.showDiscountMenu,
                                                'showDiscountMenu',
                                            )
                                    "
                                >
                                    <img :src="$assets.gray.buttonDots" alt="icon" />
                                </button>
                                <transition name="drop-from-right">
                                    <DropdownOptions
                                        v-if="displayedItemFlags.showDiscountMenu"
                                        class="discount-dropdown__dropdown"
                                        :options="dropdownOptions.discountOptions as DropdownOptionType[]"
                                        @close="
                                            () =>
                                                dropdownToggle(
                                                    !displayedItemFlags.showDiscountMenu,
                                                    'showDiscountMenu',
                                                )
                                        "
                                    />
                                </transition>
                            </div>
                        </div>
                        <div
                            v-if="$config.public.variant === 'megadescuentos'"
                            class="offer-details__votes justify-between"
                        >
                            <DWidgetHeatVote
                                :voted="!!discountData.voted && discountData.voted.type"
                                :count="discountData.temperature_sum"
                                :voteable-type="type"
                                :voteable-id="discountData.id"
                                :expire="dates.isExpired"
                                :show-expire="true"
                            />
                            <button
                                type="button"
                                class="offer-details__comments-count"
                                @click="() => scrollToCommentsSection('#bloque-de-comentarios')"
                            >
                                <img :src="$assets.gray.comment" alt="icon" />
                                <span>{{ discountData.comments_count }}</span>
                            </button>
                        </div>
                        <h1
                            class="offer-details__title"
                            :class="
                                buttonMode !== 'no-link-offer' || discountData.has_url
                                    ? 'cursor-pointer lg:hover:underline'
                                    : ''
                            "
                            @click="
                                () =>
                                    (buttonMode === 'no-link-offer' || !discountData.has_url
                                        ? () => false
                                        : isCoupon
                                          ? openCoupon
                                          : isOffer
                                            ? openOffer
                                            : () => null)()
                            "
                        >
                            {{ discountData.title }}
                        </h1>

                        <MiscPrice
                            :info="discountData"
                            :is-offer="isOffer"
                            :is-coupon="isCoupon"
                            mode="large"
                        />

                        <div v-if="discountData.store || shippingPrice" class="offer-details__store-shipping">
                            <p
                                v-if="
                                    discountData.store &&
                                    discountData.store.name.toLowerCase() !== 'sin tienda asignada'
                                "
                                class="offer-details__store"
                            >
                                {{ $lang.views.discountDetails.see_more }}
                                <NuxtLink
                                    no-prefetch
                                    :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${discountData.store.slug}`"
                                >
                                    {{ $lang.views.discountDetails.coupons }} {{ discountData.store.name }}
                                </NuxtLink>
                            </p>
                            <p v-if="shippingPrice" class="offer-details__shipping">
                                <img :src="$assets.gray.shipment" alt="icon" />
                                <span>{{ shippingPrice }}</span>
                            </p>
                        </div>

                        <ButtonDiscount
                            v-if="!(isOffer && !discountData.has_url)"
                            class="offer-details__button"
                            :info="{
                                showCode: true,
                                code: discountData.code || '',
                                slug: discountData.slug,
                                mode: buttonMode,
                                openCoupon,
                                discount: discountData,
                                openOffer,
                            }"
                        />
                        <div class="offer-details__bottom">
                            <article v-if="discountData.user" class="user-info">
                                <NuxtLink
                                    v-if="$config.public.variant === 'megadescuentos'"
                                    no-prefetch
                                    :to="'/dashboard/' + discountData.user.id"
                                >
                                    <MiscUserPic :user="discountData.user" class="h-8 w-8 flex-none" />
                                </NuxtLink>
                                <div v-else>
                                    <MiscUserPic :user="discountData.user" class="h-8 w-8 flex-none" />
                                </div>
                                <div class="user-info__details">
                                    <div class="user-info__title">
                                        <NuxtLink
                                            v-if="$config.public.variant === 'megadescuentos'"
                                            no-prefetch
                                            :to="'/dashboard/' + discountData.user.id"
                                            class="user-info__name"
                                        >
                                            {{ discountData.user.username }}
                                        </NuxtLink>

                                        <p v-else class="user-info__name">
                                            {{ discountData.user.username }}
                                        </p>
                                        <MiscBadge
                                            v-if="discountData.user.role.name !== 'Usuario'"
                                            :text="discountData.user.role.name"
                                            text-style="text-[0.65rem]"
                                            :show-text-in-mobile="true"
                                        />
                                    </div>
                                    <p class="user-info__timestamp">
                                        {{ $lang.views.discountDetails.member_since }}
                                        {{ dates.memberSince }}
                                    </p>
                                </div>
                            </article>

                            <button
                                v-if="$config.public.variant === 'megadescuentos'"
                                type="button"
                                class="offer-details__save"
                                @click="() => savePost(discountData)"
                            >
                                <img :src="$assets[saved ? 'primary' : 'gray'].bookmark" alt="icon" />
                            </button>
                        </div>
                    </div>
                </article>

                <div class="meta-info">
                    <section class="offer-description">
                        <h3 class="offer-description__title">
                            {{ $lang.views.discountDetails.details }}
                        </h3>
                        <div
                            ref="description"
                            class="offer-description__content"
                            v-html="contentDescription"
                        />
                    </section>
                    <section v-if="anyInformationExists" class="meta-info__section">
                        <h3 class="meta-info__title m-1 inline-block">
                            {{ $lang.views.discountDetails.information }}
                        </h3>

                        <client-only>
                            <p v-if="dates.endToday || dates.endTomorrow" class="meta-info__end-soon">
                                <picture class="icon-wrapper">
                                    <img
                                        :src="$assets.red.hourglass"
                                        class="icon"
                                        alt="Icono tiempo restante"
                                        width="6px"
                                        height="6px"
                                    />
                                </picture>
                                <span>
                                    {{
                                        (dates.endToday && $lang.views.discountDetails.today) ||
                                        (dates.endTomorrow && $lang.views.discountDetails.tomorrow)
                                    }}
                                </span>
                            </p>
                        </client-only>
                        <p v-if="isExclusive" class="meta-info__exclusive">
                            <picture class="icon-wrapper">
                                <img :src="$assets.orange.exclusive" alt="icon" />
                            </picture>
                            <span>{{ $lang.views.discountDetails.exclusive }}</span>
                        </p>
                        <p v-if="isVerified" class="meta-info__verified">
                            <picture class="icon-wrapper">
                                <img :src="$assets.green.verified" alt="icon" />
                            </picture>
                            <span>{{ $lang.views.discountDetails.verified }}</span>
                        </p>
                        <p v-if="dates.startingDate" class="meta-info__start-in">
                            <picture class="icon-wrapper">
                                <img :src="$assets.yellow.offerStart" alt="icon" />
                            </picture>
                            <span>
                                {{ $lang.views.discountDetails.start_at }}
                                <span class="font-semibold">{{ dates.startingDate }}h</span>
                            </span>
                        </p>
                        <p v-if="dates.endingDate" class="meta-info__end-in">
                            <picture class="icon-wrapper">
                                <img :src="$assets.gray.clock" alt="icon" />
                            </picture>
                            <span>
                                {{ $lang.views.discountDetails.end_at }}
                                <span class="font-semibold">{{ dates.endingDate }}h</span>
                            </span>
                        </p>
                        <p v-if="discountData.is_local" class="meta-info__is-local">
                            <picture class="icon-wrapper">
                                <img :src="$assets.gray.local" alt="icon" />
                            </picture>
                            <span>{{ $lang.views.discountDetails.local }}</span>
                        </p>
                        <p
                            v-if="discountData.shipping_from && discountData.shipping_from.name"
                            class="meta-info__shipping"
                        >
                            <picture class="icon-wrapper">
                                <img :src="$assets.gray.plane" alt="icon" />
                            </picture>
                            <span
                                >{{ $lang.views.discountDetails.sended_from }}
                                {{ discountData.shipping_from.name }}</span
                            >
                        </p>
                    </section>
                    <section v-if="categoriesCollection.length > 0" class="meta-info__section">
                        <h3 class="meta-info__title m-1 inline-block">
                            {{ $lang.views.discountDetails.categories }}
                        </h3>

                        <NuxtLink
                            no-prefetch
                            v-for="category in categoriesCollection"
                            :key="category.id"
                            :to="`/${$lang.routes.categories}/${category.slug}`"
                            class="meta-info__category"
                        >
                            {{ category.name }}
                        </NuxtLink>
                    </section>
                </div>

                <section class="social-networks">
                    <h3 class="meta-info__title">{{ $lang.views.discountDetails.share }}</h3>
                    <ul class="social-networks__list">
                        <li class="social-networks__item">
                            <a
                                :href="facebook"
                                target="_blank"
                                rel="nofollow noreferrer noopener"
                                class="social-networks__link"
                            >
                                <img
                                    :src="$assets.social.facebookNotCircular"
                                    alt="Facebook"
                                    title="Facebook"
                                />
                            </a>
                        </li>
                        <li class="social-networks__item">
                            <a
                                :href="twitter"
                                target="_blank"
                                rel="nofollow noreferrer noopener"
                                class="social-networks__link"
                            >
                                <img :src="$assets.social.twitterNewLogoNoCircular" alt="X" title="X" />
                            </a>
                        </li>
                        <li class="social-networks__item">
                            <a
                                :href="whatsapp"
                                target="_blank"
                                rel="nofollow noreferrer noopener"
                                class="social-networks__link"
                            >
                                <img
                                    :src="$assets.social.whatsappNotCircular"
                                    alt="Whatsapp"
                                    title="Whatsapp"
                                />
                            </a>
                        </li>
                        <li class="social-networks__item">
                            <a
                                :href="telegram"
                                target="_blank"
                                rel="nofollow noreferrer noopener"
                                class="social-networks__link"
                            >
                                <img
                                    :src="$assets.social.telegramNotCircular"
                                    alt="Telegram"
                                    title="Telegram"
                                />
                            </a>
                        </li>
                    </ul>
                </section>

                <p class="site-message">
                    {{ $lang.views.discountDetails.affiliate_comission }}
                    <NuxtLink
                        :to="'/' + $lang.routes.privacy"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                        >{{ $lang.views.discountDetails.read_more }}.</NuxtLink
                    >
                </p>
            </main>

            <div
                v-if="similarDiscounts && similarDiscounts.length"
                class="mt-5 min-h-[287px] px-2 xs:px-0 lg:min-h-[331px]"
            >
                <h2 class="my-3 text-2xl font-semibold text-gray-800">
                    {{
                        isCoupon
                            ? $lang.views.discountDetails.related_coupons
                            : $lang.views.discountDetails.related_offers
                    }}
                </h2>
                <WidgetSlider class="h-[243px] lg:h-[287px]">
                    <WidgetSliderItem v-for="(item, key) in similarDiscounts" :key="key">
                        <DCardVertical :info="item" :lazy-image="key > 1" />
                    </WidgetSliderItem>
                </WidgetSlider>
            </div>

            <MiscNoResults
                v-else
                :title="`${$lang.views.discountDetails.no_exist} ${variant.wordPlural} ${$lang.views.discountDetails.similar_by_now}`"
                mode="discounts"
            />

            <h2
                v-if="$config.public.variant === 'megadescuentos'"
                id="bloque-de-comentarios"
                class="mb-3 mt-5 text-xl font-semibold"
            >
                {{ $lang.views.discountDetails.comments }}
            </h2>

            <div
                v-if="$config.public.variant === 'megadescuentos'"
                class="discount-details-component__comments"
                :class="{ 'grayscale filter': dates.isExpired }"
            >
                <div class="comments-top">
                    <img class="comments-top__icon" :src="$assets.black.comment" alt="icon" />
                    <span>{{ discountData.comments_count }}</span>
                    <button
                        class="comments-top__follow-button"
                        :class="{ following: follow }"
                        type="button"
                        @click="follow = !follow"
                    >
                        {{
                            follow
                                ? $lang.views.discountDetails.following
                                : $lang.views.discountDetails.follow
                        }}
                    </button>
                </div>
                <div class="px-4">
                    <CInputMakeComment
                        class="mb-3"
                        :user="AuthStore.UserData || undefined"
                        :floated-submit="true"
                        @reply="createComment"
                        :replying="creatingNewComment"
                        :reply-callback="createComment"
                        isMain
                        toggleMode
                    />

                    <div class="comment-dropdown">
                        <button
                            type="button"
                            class="comment-dropdown__button"
                            @click="
                                () => dropdownToggle(!displayedItemFlags.showCommentMenu, 'showCommentMenu')
                            "
                        >
                            <span>{{
                                nextPageQuery.order_by === 'most-liked'
                                    ? $lang.views.discountDetails.more_relevant
                                    : $lang.views.discountDetails.more_recent
                            }}</span>
                            <img :src="$assets.gray.arrowDown" alt="icon" />
                        </button>
                        <transition name="drop-from-left">
                            <DropdownOptions
                                v-if="displayedItemFlags.showCommentMenu"
                                class="comment-dropdown__dropdown"
                                :active="activeFilter"
                                :options="dropdownOptions.commentOptions"
                                @close="
                                    () =>
                                        dropdownToggle(!displayedItemFlags.showCommentMenu, 'showCommentMenu')
                                "
                            />
                        </transition>
                    </div>
                    <MiscLazy mode="comments-list" @intersected="loadComments">
                        <div v-if="loadingComments && comments.length === 0">Loading...</div>
                        <template v-else-if="comments.length > 0">
                            <ul class="space-y-4">
                                <li v-for="(comment, index) in comments" :key="index">
                                    <CCardCommentThread :comment="comment" />
                                </li>
                            </ul>
                            <ButtonLoadMore
                                v-if="showLoadComments"
                                class="mb-1 mt-3"
                                :handler="loadComments"
                                :loading="loadingComments"
                            />
                        </template>
                        <MiscNoResults
                            v-else
                            mode="comments"
                            :title="$lang.views.discountDetails.no_comments_yet"
                            :subtitle="$lang.views.discountDetails.be_first_commenting"
                        />
                    </MiscLazy>
                </div>
            </div>

            <DWidgetDetailsFooter
                :info="{
                    similar_categories: responseData.similar_categories || undefined,
                    similar_stores: responseData.similar_stores || undefined,
                    popular_stores: stores || undefined,
                }"
            />
        </div>
    </div>
</template>

<style lang="postcss" scoped>
.discount-details-component {
    &__featured-offers-placeholder {
        @apply min-h-[335px] pt-5 lg:min-h-[335px];
    }
    &__sub-container {
        @apply container mx-auto max-w-5xl px-0 pb-5 xs:px-2;
    }
    &__main {
        @apply border-y bg-white px-2 py-2 xs:rounded-xl xs:border-x lg:py-2;
        .offer-details {
            @apply pb-2 lg:flex lg:space-x-5;

            &__image-content {
                @apply relative block h-44 w-full lg:h-64;
            }
            &__image {
                &:hover ~ .offer-details__image-external {
                    @apply hidden;
                }
                img {
                    @apply h-full w-full cursor-openlink object-contain pb-2 lg:pb-0;
                }
            }
            &__image-zoomin {
                @apply absolute bottom-3 right-0 flex items-center justify-center gap-2 rounded-lg border border-gray-300 border-opacity-40 bg-white p-2 font-medium shadow-[rgba(0,_0,_0,_0.2)_0px_2px_4px_0px] hover:text-site-primary lg:right-3;
                &:hover ~ &-icon {
                    @apply text-site-primary;
                }
                p {
                    @apply hidden lg:block;
                }
            }
            &__image-external {
                @apply absolute bottom-3 left-0 text-gray-800 lg:left-3;
            }

            &__right {
                @apply space-y-2 pb-4 lg:max-w-sm lg:flex-none lg:flex-grow lg:pb-0;
            }
            &__top {
                @apply flex items-center justify-between;
            }
            &__dates {
                @apply flex items-center gap-1 text-xs text-gray-800;
                img {
                    @apply h-4 w-4;
                }
                &-created {
                    @apply flex items-center space-x-1;
                }
            }
            &__votes {
                @apply flex items-center;
            }
            &__participation {
                @apply space-y-1 text-sm text-gray-800;
                span {
                    @apply font-semibold text-gray-800;
                }
            }
            &__comments-count {
                @apply flex items-center space-x-1 text-sm text-gray-800 lg:hover:underline;
                img {
                    @apply h-4 w-4;
                }
            }
            &__title {
                @apply text-xl font-semibold leading-6;
            }
            &__store-shipping {
                @apply flex items-center justify-between;
            }
            &__store {
                @apply text-sm;
                a {
                    @apply font-semibold text-site-primary lg:hover:underline;
                }
            }
            &__shipping {
                @apply flex items-center space-x-1 text-xs;
            }
            &__bottom {
                @apply flex justify-between space-x-2;
                .user-info {
                    @apply flex space-x-2;
                    &__title {
                        @apply flex items-center space-x-1;
                    }
                    &__name {
                        @apply text-xs font-semibold lg:hover:underline;
                    }
                    &__timestamp {
                        @apply text-xs text-gray-800;
                    }
                }
            }
            &__save {
                @apply h-10 w-10 flex-none;
                img {
                    @apply h-full w-full object-contain p-2;
                }
            }
        }
        .meta-info {
            &__section {
                @apply border-t py-3;
            }
            &__title {
                @apply font-semibold;
            }
            &__start-in {
                @apply text-site-secondary;
                img {
                    @apply mr-2;
                }
            }
            &__exclusive {
                @apply text-gray-800;
            }
            &__verified {
                @apply text-gray-800;
            }
            &__end-in {
                @apply text-gray-800;
            }
            &__is-local,
            &__shipping {
                @apply text-gray-800;
            }

            &__end-soon {
                @apply font-semibold text-red-500;
            }
            &__end-soon,
            &__start-in,
            &__end-in,
            &__exclusive,
            &__verified,
            &__is-local,
            &__shipping {
                @apply m-1 inline-flex items-center space-x-1 rounded border border-gray-100 px-3 py-1 text-xs shadow-md;
                .icon-wrapper {
                    @apply h-4 w-6 flex-none;
                    img {
                        @apply h-full w-full object-contain;
                    }
                }
            }
            &__category {
                @apply m-1 inline-block rounded border border-gray-100 px-3 py-1 text-xs text-gray-800 shadow-lg;
            }
        }
        .offer-description {
            @apply border-t pb-2;
            &__title {
                @apply px-1 py-1 font-semibold lg:py-2;
            }
            &__content {
                @apply text-base;
            }
        }
        .social-networks {
            @apply border-y px-1 py-2 sm:flex sm:items-center sm:space-x-5 lg:py-3;
            &__title {
                @apply text-lg font-semibold;
            }
            &__list {
                @apply flex space-x-4;
            }
            &__item {
                @apply rounded-full border border-gray-50 shadow-md;
            }
            &__link {
                @apply block h-10 w-10;
                img {
                    @apply h-full w-full object-contain p-2.5;
                }
            }
        }
        .site-message {
            @apply pt-2 text-xs italic text-gray-800;
            a {
                @apply font-semibold text-site-primary lg:hover:underline;
            }
        }
    }
    &__comments {
        @apply border-y bg-white py-4 xs:rounded-xl xs:border-x lg:py-2;
        .comments-top {
            @apply mb-3 flex items-center space-x-2 border-b px-4 pb-2 text-sm font-semibold;
            &__icon {
                @apply h-4 w-4;
            }
            &__follow-button {
                @apply rounded-full border border-site-primary px-4 py-0.5 text-xs font-medium text-site-primary transition-colors;
                &.following {
                    @apply bg-site-primary text-white;
                }
            }
        }
    }
    .comment-dropdown {
        @apply relative z-10 mb-3;
        &__button {
            @apply flex items-center space-x-2 brightness-0;
            img {
                @apply w-4;
            }
        }
        &__dropdown {
            @apply absolute left-0 top-full w-60 rounded-xl;
        }
    }
    .discount-dropdown {
        @apply relative z-10 h-4;
        &__button {
            @apply h-4 w-4 flex-none;
            img {
                @apply h-full w-full object-contain;
            }
        }
        &__dropdown {
            @apply absolute right-0 top-full w-60 rounded-xl;
        }
    }
}
</style>
<style lang="postcss" scoped>
.discount-details-component {
    .offer-description {
        &__content {
            @apply prose max-w-none px-1 text-sm text-black prose-p:mb-3;
            > * {
                @apply mt-0;
                &:last-child {
                    @apply mb-0;
                }
            }
        }
    }
}
</style>
