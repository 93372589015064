<template>
    <article class="card-main-global" :class="{ 'card-main-global__expired': isExpired }">
        <img
            v-if="info.sponsored"
            class="card-main-global__sponsored-tag"
            :src="$assets.primary.sponsored"
            alt="sponsored"
        />
        <div class="card-main-global__grid">
            <!-- IMAGE -->
            <picture
                v-if="isAdmin || (!isAuth && addExpiredGuard)"
                class="image"
                :class="/* showPrices ?  */ 'row-span-3 xl:row-span-4' /*  : 'row-span-2 xl:row-span-3' */"
                @click="openCoupon"
            >
                <img
                    :src="info.cover || info.featured_image || ''"
                    :alt="info.title"
                    :title="info.title"
                    width="87px"
                    height="77px"
                    sizes="(max-width: 419px) 87px, (min-width: 420px) 120px, (min-width: 600px) 156px, (min-width: 768px) 114px, (min-width: 1024px) 156px, (min-width: 1200px) 129px, (min-width: 1366px) 156px"
                    loading="lazy"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                />
            </picture>
            <NuxtLink
                no-prefetch
                v-else-if="!info.without_detail"
                :to="`${variant.path}/${info.slug}`"
                class="image"
                :class="/* showPrices ?  */ 'row-span-3 xl:row-span-4' /*  : 'row-span-2 xl:row-span-3' */"
                rel="follow"
            >
                <img
                    :src="info.cover || info.featured_image || ''"
                    :alt="info.title"
                    :title="info.title"
                    loading="lazy"
                    width="87px"
                    height="77px"
                    sizes="(max-width: 419px) 87px, (min-width: 420px) 120px, (min-width: 600px) 156px, (min-width: 768px) 114px, (min-width: 1024px) 156px, (min-width: 1200px) 129px, (min-width: 1366px) 156px"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                />
            </NuxtLink>
            <button
                v-else
                type="button"
                class="image"
                :class="/* showPrices ?  */ 'row-span-3 xl:row-span-4' /* : 'row-span-2 xl:row-span-3' */"
                rel="follow"
                @click=";(isCoupon && openCoupon()) || (isOffer && openOffer())"
            >
                <img
                    :src="info.cover || info.featured_image || ''"
                    :alt="info.title"
                    :title="info.title"
                    loading="lazy"
                    width="87px"
                    height="77px"
                    sizes="(max-width: 419px) 87px, (min-width: 420px) 120px, (min-width: 600px) 156px, (min-width: 768px) 114px, (min-width: 1024px) 156px, (min-width: 1200px) 129px, (min-width: 1366px) 156px"
                    :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                />
            </button>

            <!-- TOP ROW -->
            <div class="top">
                <!-- VOTOS COUNTER -->

                <div v-if="$config.public.variant === 'megadescuentos'" class="inner-left">
                    <DWidgetHeatVote
                        v-if="!(isAdmin && isCoupon)"
                        :voted="!!info.voted && info.voted.type"
                        :count="info.temperature_sum"
                        :voteable-type="info.type"
                        :voteable-id="info.id"
                        :show-expire="false"
                        :expire="isExpired"
                    />
                    <p v-if="isExpired" class="inner-left__expired-text">EXPIRADO</p>
                </div>

                <!-- VERIFICADO - EXCLUSIVO - INFORMACION -->
                <div class="inner-right">
                    <div v-if="info.is_verified && !info.is_exclusive" class="desktop">
                        <img
                            :src="$assets.green.verified"
                            class="icon"
                            alt="Icono verificado"
                            width="12px"
                            height="12px"
                        />
                        <p>{{ $lang.components.cardMainGlobal.verified }}</p>
                    </div>
                    <div v-if="info.is_exclusive" class="desktop">
                        <img
                            :src="$assets.orange.exclusive"
                            class="icon"
                            alt="icono exclusivo"
                            width="12px"
                            height="12px"
                        />
                        <p>{{ $lang.components.cardMainGlobal.exclusive }}</p>
                    </div>
                    <client-only>
                        <div v-if="endInLessThanTwoDays" class="desktop">
                            <img
                                :src="$assets.red.timeleftsvg"
                                class="icon"
                                alt="Icono tiempo restante"
                                width="12px"
                                height="12px"
                            />
                            <p class="offer-time-text font-semibold text-red-500">
                                {{
                                    endToday
                                        ? $lang.components.cardMainGlobal.today
                                        : $lang.components.cardMainGlobal.tomorrow
                                }}
                            </p>
                        </div>
                    </client-only>
                    <div>
                        <img
                            :src="
                                refreshedAt30MinsAgo
                                    ? $assets.gray.fire
                                    : updatedAt && !areTimestampsEqual
                                      ? $assets.gray.refreshedAt
                                      : $assets.gray.clock
                            "
                            class="flex-none"
                            alt="icono actualizado"
                            width="12px"
                            height="12px"
                        />
                        <template v-if="updatedAt && !areTimestampsEqual">
                            <p class="desktop">
                                {{ $lang.components.cardMainGlobal.updated_at }}
                                {{ timeAgo }}
                            </p>
                            <p class="mobile">
                                {{ $lang.components.cardMainGlobal.time_ago }}
                                {{ timeAgo }}
                            </p>
                        </template>
                        <p v-else>
                            {{ $lang.components.cardMainGlobal.time_ago }}
                            {{ timeAgo }}
                        </p>
                    </div>
                    <div v-if="isProgrammed.status" class="desktop">
                        <i class="fal fa-alarm-clock"></i>
                        <p>{{ isProgrammed.text }}</p>
                    </div>
                    <div v-if="endingDate.length && !endInLessThanTwoDays" class="desktop">
                        <img
                            :src="$assets.gray.offerFinishIn"
                            class="icon"
                            alt="Icono finaliza"
                            width="12px"
                            height="12px"
                        />
                        <p class="start-end-text">
                            {{ $lang.components.cardMainGlobal.end_at }}
                            {{ endingDate }}
                        </p>
                    </div>
                    <div v-if="info.is_local" class="desktop">
                        <span class="block h-3 w-3">
                            <img
                                :src="$assets.gray.local"
                                alt="icono gps"
                                class="block h-full w-full object-contain"
                            />
                        </span>
                        <p class="offer-time-text">{{ $lang.components.cardMainGlobal.local }}</p>
                    </div>
                </div>
            </div>

            <!-- HEAT / TITLE / DESCRIPTION -->
            <div class="middle">
                <div class="middle_header">
                    <div
                        v-if="isAdmin || (!isAuth && addExpiredGuard)"
                        class="middle_header_title"
                        @click="openCoupon"
                    >
                        <p>
                            {{ info.title }}
                            <span v-if="info.is_new ? true : false" class="middle_header_new-label">
                                {{ $lang.components.cardMainGlobal.new }}
                            </span>
                        </p>
                    </div>
                    <NuxtLink
                        no-prefetch
                        v-else-if="!info.without_detail"
                        class="middle_header_title"
                        :to="`${variant.path}/${info.slug}`"
                        rel="follow"
                    >
                        <p>
                            {{ info.title }}
                            <span v-if="info.is_new ? true : false" class="middle_header_new-label">
                                {{ $lang.components.cardMainGlobal.new }}
                            </span>
                        </p>
                    </NuxtLink>
                    <div
                        v-else
                        class="middle_header_title"
                        @click=";(isCoupon && openCoupon()) || (isOffer && openOffer())"
                    >
                        <p>
                            {{ info.title }}
                            <span v-if="info.is_new ? true : false" class="middle_header_new-label">
                                {{ $lang.components.cardMainGlobal.new }}
                            </span>
                        </p>
                    </div>

                    <div
                        v-if="
                            !inStoreRoute &&
                            info.store &&
                            !info.store.name.toLowerCase().includes('sin tienda asignada')
                        "
                        class="middle_header_see-more-offers"
                    >
                        <span class="middle_header_see-more-offers_text">{{
                            $lang.components.cardMainGlobal.see_more
                        }}</span
                        >&nbsp;<NuxtLink
                            no-prefetch
                            :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${info.store.slug}`"
                            class="middle_header_see-more-offers_text-2"
                            rel="follow"
                        >
                            {{ info.store.name }}
                        </NuxtLink>
                    </div>
                </div>
                <div
                    v-if="info.description && content"
                    class="middle_header_description"
                    v-html="content"
                ></div>
            </div>

            <!-- PRICE / START TIME / END TIME -->
            <div v-if="showPrices" class="prices-and-time">
                <p v-if="showNewPrice" class="new-price">{{ newPrice }}</p>

                <p v-if="showOldPrice" class="old-price">{{ $lang.symbol + info.old_price }}</p>

                <p v-if="showPercent" class="discount-percent">{{ info.discount_percent + '%' }}</p>

                <span v-if="shippingPrice" class="free-ship">
                    <img :src="$assets.gray.shipment" alt="envio" width="12px" height="12px" />
                    {{ shippingPrice }}
                </span>

                <div v-if="startingDate" class="start-in">
                    <img :src="$assets.yellow.offerStart" alt="icono reloj" width="12px" height="12px" />
                    <span class="start-end-text"> {{ $lang.components.cardMainGlobal.start_at }} </span>
                    <span class="offer-time-text">
                        {{ startingDate }}
                    </span>
                </div>
            </div>

            <!-- USER / SEE COUPON -->
            <div class="bottom">
                <div v-if="$config.public.variant === 'megadescuentos'" class="user-profile">
                    <div v-if="info.user" class="user-profile_user">
                        <MiscUserPic class="h-8 w-8" :user="info.user" />

                        <p>
                            <span class="user-profile_user-text">{{ info.user.username }}</span>
                        </p>

                        <MiscBadge
                            v-if="info.user.role && info.user.role.name !== 'Usuario'"
                            :text="info.user.role.name"
                        />
                    </div>

                    <div v-if="!info.without_detail" class="user-profile_comments-and-bookmark">
                        <button type="button" @click="() => savePost(info)">
                            <img
                                :src="$assets[saved ? 'primary' : 'gray'].bookmark"
                                class="user-profile_comments-and-bookmark_icon"
                                alt="agregar a favoritos"
                                width="10px"
                                height="10px"
                            />
                        </button>
                        <NuxtLink
                            no-prefetch
                            v-if="!isAdmin && !info.without_detail"
                            :to="`${variant.path}/${info.slug}#bloque-de-comentarios`"
                            rel="follow"
                            class="user-profile_comments-and-bookmark_comments"
                        >
                            <img
                                :src="$assets.gray.comment"
                                class="user-profile_comments-and-bookmark_icon"
                                alt="comentarios"
                                width="10px"
                                height="10px"
                            />
                            <span class="self-center">{{ info.comments_count }}</span>
                        </NuxtLink>
                    </div>
                </div>

                <!-- START BOTONES MAIN -->
                <ButtonDiscount
                    :info="{
                        inCard: true,
                        isAdmin: isAdmin,
                        slug: info.slug,
                        code: info.code || '',
                        mode: buttonMode,
                        showCode,
                        openCoupon,
                        discount: info,
                        openOffer,
                    }"
                />
                <!-- END BOTONES MAIN -->
            </div>
        </div>
    </article>
</template>

<script lang="ts" setup>
// @ts-ignore
import { formatInTimeZone } from 'date-fns-tz'
import { differenceInMinutes, isBefore, isToday, isTomorrow, parseISO } from 'date-fns'
import { useAuthStore } from '~/store/auth'
import type { Models } from '~/types/models'
import { useDateFunctions } from '~/composables/DateFunctions'

import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { defaultImages, variants } = useVariantsDictionary()

const config = useRuntimeConfig()

const { getDateDifference } = useDateFunctions()

const props = defineProps({
    info: {
        type: Object as PropType<Models.Discount>,
        required: true,
    },
})

const AuthStore = useAuthStore()
const Route = useRoute()
const { $openers, $lang } = useNuxtApp()

const { savePost, saved } = usePostActions()

saved.value = props.info.saved || false

const isOffer = computed(() => props.info.type === 'offer')
const isCoupon = computed(() => props.info.type === 'coupon')
const defaultImage = computed(() => defaultImages[props.info.type])

const isExpired = computed(() => {
    return props.info.expired_at ? isBefore(parseISO(props.info.expired_at), new Date()) : false
})
const isAdmin = computed(() => {
    return props.info.is_admin
})
const isAuth = computed(() => {
    return AuthStore.IsAuth
})
const isProgrammed = computed(() => {
    if (!!props.info.start_at && parseISO(props.info.start_at) > new Date()) {
        return { text: $lang.components.cardMainGlobal.programmed, status: true }
    }
    // return { text: 'Programado', status: true }
    return { text: '', status: false }
})
const addExpiredGuard = computed(() => {
    return props.info.expired_at
        ? isBefore(parseISO(props.info.expired_at), new Date(new Date().setMonth(new Date().getMonth() - 2)))
        : false
})

const startingDate = computed(() => {
    const date = props.info.start_at && parseISO(props.info.start_at)

    return date && date > new Date() ? formatInTimeZone(date, $lang.timeZone, 'dd/MM/yyyy HH:mm') : false
})
const variant = computed(() => variants[props.info.type])
const openCoupon = () => {
    if (isAuth.value || !addExpiredGuard.value) {
        if (props.info.is_admin || props.info.without_detail) {
            $openers.openCoupon(
                props.info.slug,
                props.info.without_detail ? '' : props.info.store?.slug || '',
            )
        } else {
            $openers.openCoupon(props.info.slug)
        }
    } else {
        AuthStore.setAuthModal({
            type: 'sign-in',
        })
    }
}

const openOffer = () => {
    if (!addExpiredGuard.value) {
        $openers.openOffer(props.info.id)
    } else {
        AuthStore.setAuthModal({
            type: 'sign-in',
        })
    }
}

const endToday = computed(() => {
    return !!props.info.expired_at && isToday(parseISO(props.info.expired_at))
})
const endTomorrow = computed(() => {
    return !!props.info.expired_at && isTomorrow(parseISO(props.info.expired_at))
})
const endInLessThanTwoDays = computed(() => {
    return endToday.value || endTomorrow.value
})

const refreshedAt30MinsAgo = computed(() => {
    const date = props.info.updated_at && parseISO(props.info.updated_at)
    const difference = !!date && differenceInMinutes(new Date(), date)

    return (difference && difference > 0 && difference < 30 && difference) || 0
})

const createdAt = computed(() => {
    return parseISO(props.info.created_at)
})
const updatedAt = computed(() => {
    return props.info.updated_at ? parseISO(props.info.updated_at) : null
})
const areTimestampsEqual = computed(() => {
    return (
        !!createdAt.value &&
        !!updatedAt.value &&
        getDateDifference(createdAt.value) === getDateDifference(updatedAt.value)
    )
})

const timeAgo = computed(() => {
    return getDateDifference(updatedAt.value || '')
})

const endingDate = computed(() => {
    const date = props.info.expired_at && parseISO(props.info.expired_at)

    return date ? formatInTimeZone(date, $lang.timeZone, 'dd/MM/yyyy') : ''
})
const content = computed(() => {
    return props.info.description?.replace(/<[^>]+>/g, '')
})

const shippingPrice = computed(() => {
    return isFreeShipping.value
        ? 'Envío gratis'
        : !!props.info.shipping_price &&
              props.info.shipping_price !== '0' &&
              $lang.symbol + props.info.shipping_price
})
const isFreeShipping = computed(() => {
    return props.info.discount_type === 'envío gratis' || !!props.info.shipping_free
})
const showOldPrice = computed(() => {
    return isOffer.value && !!props.info.old_price && !!props.info.new_price && props.info.new_price !== '0'
})
const showNewPrice = computed(() => {
    return (
        (isOffer.value && !!props.info.new_price) ||
        (isCoupon.value &&
            (((props.info.discount_type === 'precio' || props.info.discount_type === 'porcentaje') &&
                props.info.discount_value !== '0') ||
                props.info.discount_type === 'envío gratis'))
    )
})
const newOfferPrice = computed(() => {
    return props.info.new_price === '0'
        ? $lang.components.cardMainGlobal.free
        : $lang.symbol + props.info.new_price
})
const newCouponPrice = computed(() => {
    if (config.public.variant === 'bchollos') {
        return props.info.discount_type === 'envío gratis'
            ? $lang.components.cardMainGlobal.free_ship
            : props.info.discount_type === 'precio'
              ? $lang.symbol + props.info.discount_value + ' MENOS'
              : props.info.discount_value === '100'
                ? $lang.components.cardMainGlobal.free
                : props.info.discount_value + '% MENOS'
    } else {
        return props.info.discount_type === 'envío gratis'
            ? $lang.components.cardMainGlobal.free_ship
            : props.info.discount_type === 'precio'
              ? $lang.symbol + props.info.discount_value + ' OFF'
              : props.info.discount_value === '100'
                ? $lang.components.cardMainGlobal.free
                : props.info.discount_value + '% OFF'
    }
})
const newPrice = computed(() => {
    return (isOffer.value && newOfferPrice.value) || (isCoupon.value && newCouponPrice.value) || ''
})
const showPercent = computed(() => {
    return (
        !!props.info.old_price &&
        !!props.info.new_price &&
        !!props.info.discount_percent &&
        props.info.new_price !== '0'
    )
})
const showPrices = computed(() => {
    return showNewPrice.value || !!startingDate.value
})

const buttonMode = computed(() => {
    if (isOffer.value) {
        if (props.info.has_url) {
            if (props.info.code) {
                return 'offer-code'
            } else {
                return 'offer'
            }
        } else {
            return 'no-link-offer'
        }
    } else if (isCoupon.value) {
        if (props.info.has_url) {
            return 'coupon'
        } else {
            return 'no-link-coupon'
        }
    }
    return ''
})
const $config = useRuntimeConfig()
const showCode = computed(() => {
    return buttonMode.value === 'no-link-coupon' ? Route.name === $lang.routes.coupons + '-slug' : true
})
const inStoreRoute = computed(() => {
    return Route.path.includes(`/${props.info.store?.slug}`)
})
</script>

<style lang="postcss" scoped>
.card-main-global {
    @apply relative rounded-xl border bg-white;
    &__expired {
        @apply opacity-90 grayscale filter;
    }
    &__sponsored-tag {
        @apply absolute -left-1.5 top-3;
    }
    &__grid {
        @apply grid grid-cols-12 items-center justify-center gap-x-2 gap-y-1 p-2;
        .image {
            @apply col-span-4 row-start-1 flex cursor-pointer justify-start md:col-span-2;
            img {
                @apply block h-full w-full object-contain;
                max-width: 9.8rem;
                max-height: 15.5rem;
            }
        }
        .top {
            @apply col-span-8 col-start-5 row-span-1 flex h-8 items-center justify-between text-xs text-gray-800 md:col-span-10 md:col-start-3 lg:text-sm;

            .inner-left {
                @apply flex items-center space-x-3;
                &__expired-text {
                    @apply hidden text-lg font-medium sm:block;
                }
            }
            .inner-right {
                @apply flex flex-wrap justify-end xl:gap-x-2;
                div {
                    @apply flex flex-none items-center gap-x-1 text-xs;
                    p {
                        @apply whitespace-nowrap;
                        span {
                            @apply whitespace-nowrap;
                        }
                    }
                    img,
                    i {
                        @apply h-3 w-3 flex-none;
                    }
                }
                .desktop {
                    @apply hidden xl:flex;
                }
                .mobile {
                    @apply xl:hidden;
                }
            }
        }

        .middle {
            @apply col-span-8 col-start-5 row-span-1 space-y-1 md:col-span-10 md:col-start-3;

            &_header {
                @apply items-center gap-4 space-y-1;
                &_title {
                    @apply cursor-pointer;
                    p {
                        @apply line-clamp-3 cursor-pointer gap-2 font-medium leading-5 text-gray-800 xs:line-clamp-2 md:text-lg md:leading-6 lg:hover:underline;
                    }
                }
                &_see-more-offers {
                    @apply gap-1 text-sm;
                    &_text {
                        @apply text-gray-800;
                    }
                    &_text-2 {
                        @apply text-site-primary hover:underline;
                    }
                }
                &_new-label {
                    @apply self-center rounded-lg bg-site-primary px-2 text-xs text-white;
                }
                &_description {
                    @apply hidden text-sm text-gray-800 xs:line-clamp-2;
                }
            }
        }

        .prices-and-time {
            @apply col-span-8 col-start-5 row-span-1 flex flex-wrap items-center gap-x-1.5 md:col-span-10 md:col-start-3;

            .new-price {
                @apply text-lg font-semibold xs:text-xl;
            }

            .old-price {
                @apply text-sm text-gray-800 line-through xs:text-base;
            }

            .discount-percent {
                @apply text-sm font-semibold text-site-primary xs:text-base;
            }

            .free-ship {
                @apply flex flex-nowrap gap-1 text-sm text-gray-800;
                img {
                    @apply h-3 w-3 self-center;
                }
            }

            .start-in {
                @apply flex gap-1 self-center text-xs text-[#00846a];
                img {
                    @apply h-3 w-2 self-center;
                }
                .start-end-text {
                    @apply whitespace-nowrap;
                }
                .offer-time-text {
                    @apply whitespace-nowrap font-semibold;
                }
            }
        }

        .bottom {
            @apply col-span-12 row-span-1 block justify-end space-y-2 sm:space-y-0 md:flex md:space-x-4 xl:col-span-10 xl:col-start-3;
            .user-profile {
                @apply my-2 flex w-full flex-grow justify-between gap-2 md:my-0;
                &_user {
                    @apply relative flex items-center gap-1;
                    &-text {
                        @apply line-clamp-1 self-center text-xs font-semibold;
                    }
                }
                &_comments-and-bookmark {
                    @apply flex gap-4;
                    img {
                        @apply h-5 w-5 self-center md:h-5 md:w-5;
                    }
                    &_comments {
                        @apply flex gap-1;
                        &_icon {
                            @apply flex h-4 w-4 gap-1;
                        }
                        span {
                            @apply text-sm font-medium text-gray-800;
                        }
                    }
                }
            }
        }
    }
}
</style>
