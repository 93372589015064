<template>
    <div class="header-component-menu" ref="$menu">
        <nav class="header-component-menu__outside-link">
            <ul>
                <li v-for="(link, index) in links.outside" :key="index">
                    <NuxtLink
                        no-prefetch
                        :to="link.path"
                        exact
                        exact-active-class="current-view"
                        rel="follow"
                        >{{ link.title }}</NuxtLink
                    >
                </li>
            </ul>
        </nav>
        <button
            type="button"
            class="header-component-menu__button"
            :class="{ hide: config.public.variant !== 'megadescuentos' }"
            :aria-label="showMenu ? 'Cerrar menú' : 'Abrir menú'"
            @click="() => toggleMenu(!showMenu)"
        >
            <div class="desktop" :class="{ 'is-open': showMenu }">
                <span class="text">{{ $lang.components.layoutHeaderMenu.menu }}</span>
                <picture class="icon">
                    <img
                        :src="$assets.white.menuArrow"
                        alt="menu"
                        width="12px"
                        height="6px"
                        class="h-auto w-3"
                        :class="{ '-rotate-180 transform': showMenu }"
                    />
                </picture>
            </div>
            <div class="mobile" :class="{ 'is-open': showMenu }">
                <span></span>
            </div>
        </button>

        <transition name="menu">
            <div
                v-if="showMenu"
                class="header-component-menu__links"
                :class="$config.public.variant === 'bchollos' ? 'bg-site-secondary' : 'bg-site-primary-hover'"
            >
                <nav>
                    <ul>
                        <li v-for="(link, index) in links.first" :key="index">
                            <NuxtLink no-prefetch :to="link.path" rel="follow">
                                <span>
                                    <img :src="link.icon" alt="icon" />
                                </span>
                                <p>{{ link.title }}</p>
                            </NuxtLink>
                        </li>
                    </ul>
                    <ul>
                        <li v-for="(link, index) in links.second" :key="index">
                            <NuxtLink no-prefetch :to="link.path" rel="follow">
                                <span>
                                    <img :src="link.icon" alt="icon" />
                                </span>
                                <p>{{ link.title }}</p>
                            </NuxtLink>
                        </li>
                    </ul>
                    <ul v-if="adminLinks && adminLinks.length">
                        <li v-for="(link, index) in adminLinks" :key="index">
                            <a
                                v-if="link.external"
                                :href="link.url"
                                :class="{
                                    'font-semibold text-site-secondary-hover': !!link.colour,
                                }"
                            >
                                <div :class="link.icon" class="icon" />

                                <p>{{ link.title }}</p>
                            </a>
                            <NuxtLink
                                no-prefetch
                                v-else
                                :to="link.url"
                                rel="follow"
                                :class="{
                                    [$config.public.variant === 'bchollos'
                                        ? 'font-semibold text-white'
                                        : 'font-semibold text-site-secondary-hover']: !!link.colour,
                                }"
                            >
                                <div :class="link.icon" class="icon" />

                                <p>{{ link.title }}</p>
                            </NuxtLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </transition>
        <transition>
            <MiscOverlay v-if="showMenu" class="header-component-menu__overlay" />
        </transition>
    </div>
</template>

<script lang="ts" setup>
import { type PropType } from 'vue'
import { onClickOutside } from '@vueuse/core'
import type { Models } from '~/types/models'

const { $lang } = useNuxtApp()

const config = useRuntimeConfig()

const props = defineProps({
    adminLinks: {
        type: Array as PropType<Models.Link[]>,
        required: false,
    },
})

const $menu = ref()

onClickOutside($menu, () => {
    toggleMenu(false)
})

const showMenu = ref(false)

const { $assets } = useNuxtApp()

const links = {
    outside:
        config.public.variant === 'megadescuentos'
            ? [
                  {
                      title: $lang.components.layoutHeaderMenu.home,
                      path: '/',
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.coupons,
                      path: `/${$lang.routes.coupons}`,
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.offers,
                      path: `/${$lang.routes.offers}`,
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.free,
                      path: `/${$lang.routes.free}`,
                  },
              ]
            : [
                  {
                      title: $lang.components.layoutHeaderMenu.home,
                      icon: $assets.white.homeMenu,
                      path: '/',
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.coupons,
                      icon: $assets.white.couponsMenu,
                      path: `/${$lang.routes.coupons}`,
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.blog,
                      icon: $assets.white.pencilMenu,
                      path: `/${$lang.routes.blog}`,
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.categories,
                      icon: $assets.white.categoriesMenu,
                      path: `/${$lang.routes.categories}`,
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.brands,
                      icon: $assets.white.brandsMenu,
                      path: `/${$lang.routes.brands}`,
                  },
              ],
    first: [
        {
            title: $lang.components.layoutHeaderMenu.home,
            icon: $assets.white.homeMenu,
            path: '/',
        },
        {
            title: $lang.components.layoutHeaderMenu.coupons,
            icon: $assets.white.couponsMenu,
            path: `/${$lang.routes.coupons}`,
        },
        ...(config.public.variant === 'megadescuentos'
            ? [
                  {
                      title: $lang.components.layoutHeaderMenu.offers,
                      icon: $assets.white.offersMenu,
                      path: `/${$lang.routes.offers}`,
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.free,
                      icon: $assets.white.freeMenu,
                      path: `/${$lang.routes.free}`,
                  },
              ]
            : []),
        {
            title: $lang.components.layoutHeaderMenu.blog,
            icon: $assets.white.pencilMenu,
            path: `/${$lang.routes.blog}`,
        },
    ],
    second: [
        {
            title: $lang.components.layoutHeaderMenu.categories,
            icon: $assets.white.categoriesMenu,
            path: `/${$lang.routes.categories}`,
        },
        {
            title: $lang.components.layoutHeaderMenu.brands,
            icon: $assets.white.brandsMenu,
            path: `/${$lang.routes.brands}`,
        },
        ...(config.public.variant === 'megadescuentos'
            ? [
                  {
                      title: $lang.components.layoutHeaderMenu.chats,
                      icon: $assets.white.chatMenu,
                      path: `/${$lang.routes.forum}`,
                  },
                  {
                      title: $lang.components.layoutHeaderMenu.novelties,
                      icon: $assets.white.newsMenu,
                      path: `/${$lang.routes.novelties}`,
                  },
              ]
            : []),
    ],
}

const toggleMenu = (show: boolean) => {
    showMenu.value = show
}

const Route = useRoute()
const fullPath = computed(() => Route.fullPath)
watch(fullPath, () => toggleMenu(false))
</script>

<style lang="postcss" scoped>
.header-component-menu {
    @apply flex items-center;
    &__outside-link {
        @apply hidden xl:block;
        ul {
            @apply flex items-center;
            li {
                a {
                    @apply block h-14 px-3 py-4 font-semibold leading-6 text-white opacity-80 lg:h-16 lg:py-5 lg:transition-colors lg:hover:bg-black lg:hover:bg-opacity-30;
                    &.current-view {
                        @apply opacity-100;
                    }
                }
            }
        }
    }
    &__button {
        @apply block h-14 w-12 flex-none lg:h-16 lg:w-auto;
        .desktop {
            @apply hidden;
        }
        @screen lg {
            .desktop {
                @apply flex h-full items-center space-x-2 px-3 opacity-80 transition-colors hover:bg-black hover:bg-opacity-20;
                span {
                    &.text {
                        @apply font-semibold text-white;
                    }
                    &.icon {
                        @apply h-2 w-4 transition-transform;
                        img {
                            @apply h-full w-full object-contain;
                        }
                    }
                }
                &.is-open {
                    @apply bg-black bg-opacity-30 opacity-100;
                }
            }
        }
        .mobile {
            @apply lg:hidden;
            span {
                @apply mx-auto block h-1 w-8 transform rounded-full bg-white transition-all;
                &::before,
                &::after {
                    @apply block h-1 transform rounded-full bg-white transition-all;
                    content: '';
                }
                &::before {
                    @apply w-8 -translate-y-2.5;
                }
                &::after {
                    @apply w-5 translate-y-1.5;
                }
            }
            &.is-open {
                span {
                    @apply w-0;
                    &::before {
                        @apply -translate-x-5 translate-y-0 -rotate-45;
                    }
                    &::after {
                        @apply w-8 -translate-x-5 -translate-y-1 rotate-45;
                    }
                }
            }
        }

        &.hide {
            @apply xl:hidden;
        }
    }
    &__links {
        @apply absolute bottom-0 left-0 right-0 top-14 z-40 block overflow-y-auto lg:top-16;
        min-height: calc(100vh - theme('spacing.14'));

        nav {
            @apply container;

            ul {
                li {
                    @apply text-white;
                    a {
                        @apply flex h-12 items-center space-x-2 lg:h-9 lg:rounded-lg lg:px-10 lg:transition-colors lg:hover:bg-black lg:hover:bg-opacity-10;
                        span,
                        div {
                            @apply h-4 w-4;
                            img {
                                @apply h-full w-full filter;
                            }
                        }
                    }
                }
            }
        }
    }

    @screen lg {
        &__links {
            @apply bottom-auto min-h-max shadow-xl;
            nav {
                @apply flex justify-center space-x-20 py-5;
            }
        }
    }
    &__overlay {
        @apply bottom-0 left-0 right-0;
        top: var(--total-header-height, 0px) !important;
    }
}
</style>
