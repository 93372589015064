import { defineStore } from 'pinia'
import { getCookie, setCookie, deleteCookie } from 'h3'
import type { Models } from '~/types/models'
import type { Api } from '~~/global'
import type { RouteLocationNormalized } from 'vue-router'

type AuthModalOptions = {
    type:
        | 'sign-in'
        | 'sign-up'
        | 'forgot-password'
        | 'email-sign-in'
        | 'reset-password'
        | 'reset-password-init'
        | 'email-verification'
        | 'accept-terms'
    info?: any
}

export const useAuthStore = defineStore('AuthStore', () => {
    const { buildHeaders, baseURL, endpoints } = useApiConfig()

    const { $constants, ssrContext, $lang } = useNuxtApp()

    const UserData = ref(null) as Ref<null | Models.User>
    const VerificationResult = ref(null) as Ref<
        null | Api.Responses.User.FailedVerification | Api.Responses.User.SuccessVerification
    >
    const SessionToken = ref(null) as Ref<null | string>
    const SessionExpirationDate = ref(null) as Ref<null | string | Date | number>
    const recoveryEmail = ref(null) as Ref<null | string>
    const AuthModal = ref(null) as Ref<AuthModalOptions | null>
    const IsAuth = computed(() => !!UserData.value)

    const UserActivitiesCount = reactive({
        notifications: 0,
        messages: 0,
    })

    const setUserActivitiesCount = (count: typeof UserActivitiesCount) => {
        UserActivitiesCount.notifications = count.notifications
        UserActivitiesCount.messages = count.messages
    }

    const getNotifications = async () => {
        const notifications = await $fetch<Api.Responses.General.NotificationsCounter>(
            endpoints.general.notificationsCounter.path,
            {
                headers: buildHeaders(SessionToken.value),
                baseURL,
                method: 'GET',
            },
        ).catch((error) => {
            console.log('Error on get notifications: ', { error })
            return error
        })
        if (
            !notifications.error &&
            notifications.data &&
            typeof notifications.data.notification_count == 'number'
        ) {
            setUserActivitiesCount({
                ...UserActivitiesCount,
                notifications: notifications.data.notification_count,
            })
        }
    }

    const getMessages = async () => {
        const messages = await $fetch<Api.Responses.General.MessagesCounter>(
            endpoints.general.messagesCounter.path,
            {
                headers: buildHeaders(SessionToken.value),
                baseURL,
                method: 'GET',
            },
        ).catch((error) => {
            console.log('Error on get messages: ', { error })
            return error
        })

        if (!messages.error && typeof messages.data.message_count == 'number') {
            setUserActivitiesCount({
                ...UserActivitiesCount,
                messages: messages.data.message_count,
            })
        }
    }

    const updateActivitiesCount = async (type?: 'messages' | 'notifications') => {
        if (type === 'messages') {
            getMessages()
        } else if (type === 'notifications') {
            getNotifications()
        } else {
            getMessages()
            getNotifications()
        }
    }

    const setAuthModal = (modal: AuthModalOptions | null) => {
        AuthModal.value = modal
    }

    const clearAuthData = () => {
        UserData.value = null
        SessionToken.value = null
        SessionExpirationDate.value = null
    }

    const setUser = (data: Models.User) => {
        UserData.value = data
    }
    const verifyEmail = async (code: string) => {
        const result = await $fetch<
            Api.Responses.User.FailedVerification | Api.Responses.User.SuccessVerification
        >(endpoints.authentication.verify.path, {
            method: 'POST',
            headers: buildHeaders(),
            params: {
                code,
            },
            baseURL,
        }).catch((error) => {
            console.log('Error on verify email: ', { error })
            return error.data
        })

        if (result.feedback === 'verified_account_success') {
            SessionToken.value = result.data.access_token

            SessionExpirationDate.value = result.data.expires_at * 1000

            const userResult = await $fetch<
                Api.Responses.User.SuccessProfile | Api.Responses.User.FailedProfile
            >(endpoints.user.profile.path, {
                method: 'GET',
                headers: buildHeaders(SessionToken.value),
                baseURL,
            }).catch((error) => {
                console.log('Error on verify email: ', { error })
                return error.data
            })

            if (userResult.feedback === 'data_success') {
                UserData.value = userResult.data.user
            }
        }
        return result
    }

    const setCookieShortcode = (access_token: string, expires_at: string) => {
        if (!ssrContext) return

        setCookie(ssrContext?.event, $constants.cookies.access_token_key, access_token, {
            path: '/',
            expires: new Date(expires_at),
            sameSite: 'strict',
        })

        setCookie(ssrContext?.event, $constants.cookies.token_expires_at_key, String(expires_at), {
            path: '/',
            expires: new Date(expires_at),
            sameSite: 'strict',
        })
    }

    /* 
    const setCookieShortcode = (access_token: string | null, expires_at: Date | null) => {
        if (!ssrContext) return

        const clear = !access_token || !expires_at

        setCookie(ssrContext?.event, $constants.cookies.access_token_key, clear ? '' : access_token, {
            path: '/',
            expires: clear ? new Date(Date.now() - 1000) : expires_at,
            sameSite: 'strict',
        })

        setCookie(
            ssrContext?.event,
            $constants.cookies.token_expires_at_key,
            clear ? '' : expires_at.toISOString(),
            {
                path: '/',
                expires: clear ? new Date(Date.now() - 1000) : expires_at,
                sameSite: 'strict',
            },
        )
    }
    
    */
    const authInit = async (Route: RouteLocationNormalized) => {
        if (!ssrContext) return

        const code = Route.query?.code as string | null | undefined

        if (code && Route.path === '/' + $lang.routes.verifyAccount) {
            VerificationResult.value = await verifyEmail(code)

            if (VerificationResult.value?.feedback === 'verified_account_success') {
                setCookieShortcode(
                    VerificationResult.value.data.access_token,
                    String(VerificationResult.value.data.expires_at * 1000),
                )
                setCookie(
                    ssrContext?.event,
                    $constants.cookies.access_token_key,
                    VerificationResult.value.data.access_token,
                    {
                        path: '/',
                        expires: new Date(VerificationResult.value.data.expires_at * 1000),
                        sameSite: 'strict',
                    },
                )

                setCookie(
                    ssrContext?.event,
                    $constants.cookies.token_expires_at_key,
                    String(VerificationResult.value.data.expires_at * 1000),
                    {
                        path: '/',
                        expires: new Date(VerificationResult.value.data.expires_at * 1000),
                        sameSite: 'strict',
                    },
                )
            }
        } else {
            const cookieToken = getCookie(ssrContext?.event, $constants.cookies.access_token_key)

            const queryToken = Route.query?.token as string | null | undefined

            const token = cookieToken || queryToken

            if (!token) return

            try {
                SessionToken.value = token

                const userResult = await $fetch<
                    Api.Responses.User.SuccessProfile | Api.Responses.User.FailedProfile
                >(endpoints.user.profile.path, {
                    method: 'GET',
                    headers: buildHeaders(SessionToken.value),
                    baseURL,
                })

                if (userResult.feedback === 'data_success') {
                    const tokenExpirationCookie = getCookie(
                        ssrContext?.event,
                        $constants.cookies.token_expires_at_key,
                    )
                    const tokenExpirationDate = Number(
                        tokenExpirationCookie || userResult.data.token_expired_at,
                    )

                    if (tokenExpirationDate && !isNaN(tokenExpirationDate)) {
                        const expirationDate = new Date(tokenExpirationDate * 1000)

                        setCookie(ssrContext?.event, $constants.cookies.access_token_key, token, {
                            path: '/',
                            expires: expirationDate,
                            sameSite: 'strict',
                        })
                        setCookie(
                            ssrContext?.event,
                            $constants.cookies.token_expires_at_key,
                            expirationDate.toString(),
                            {
                                path: '/',
                                expires: expirationDate,
                                sameSite: 'strict',
                            },
                        )
                    }
                    UserData.value = userResult.data.user
                }
            } catch (e: any) {
                /* console.log('Fail on init session: ', { e }) */

                setCookie(ssrContext?.event, $constants.cookies.access_token_key, '', {
                    path: '/',
                    expires: new Date(Date.now() - 1000),
                    sameSite: 'strict',
                })
                setCookie(ssrContext?.event, $constants.cookies.token_expires_at_key, '', {
                    path: '/',
                    expires: new Date(Date.now() - 1000),
                    sameSite: 'strict',
                })
                clearAuthData()
            }
        }
    }

    const setRecoveryEmail = (email: string) => {
        recoveryEmail.value = email
    }

    const signInWithEmail = async (body: { email: string; code: string }) => {
        const result = await $fetch<
            | Api.Responses.Auth.SuccessSignIn
            | Api.Responses.Auth.FailedSignInWithEmail
            | Api.Responses.Auth.FailedSignIn
        >(endpoints.authentication.signIn.withEmailCode.path, {
            method: 'POST',
            body,
            headers: buildHeaders(SessionToken.value),
            baseURL,
        }).catch((error) => {
            console.log('Fail on signIn with email: ', { error })
            error.data
        })

        return result
    }

    const signIn = async (body: { password: string; username: string }) => {
        const result = await $fetch<Api.Responses.Auth.SuccessSignIn | Api.Responses.Auth.FailedSignIn>(
            endpoints.authentication.signIn.withPassword.path,
            {
                method: 'POST',
                body,
                baseURL,
                headers: buildHeaders(SessionToken.value),
            },
        ).catch((error) => {
            console.log('Fail on signIn: ', { error })
            return error.data
        })

        return result
    }

    const signOut = async (currentRoute: string) => {
        try {
            const response = await $fetch<
                Api.Responses.Auth.SuccessSignOut | Api.Responses.Auth.FailedSignOut
            >(endpoints.authentication.signOut.path, {
                headers: buildHeaders(SessionToken.value),
                baseURL,
                method: 'GET',
            })

            if (response.feedback === 'closed_session_success') {
                if (currentRoute === 'homepage') {
                    location.reload()
                } else {
                    location.href = '/'
                }
            }
        } catch (error) {
            console.log('Fail on singOut: ', { error })
        }
    }

    return {
        UserData,
        SessionToken,
        IsAuth,
        AuthModal,
        recoveryEmail,
        UserActivitiesCount,
        VerificationResult,
        updateActivitiesCount,
        signOut,
        signInWithEmail,
        setRecoveryEmail,
        signIn,
        verifyEmail,
        setAuthModal,
        setUser,
        authInit,
    }
})
