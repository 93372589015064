<template>
    <section
        v-if="info.similar_categories || info.similar_stores || info.popular_stores"
        class="discount-details-footer"
    >
        <div class="discount-details-footer-wrapper">
            <div v-if="info.similar_categories" class="discount-paragraph">
                <div class="discount-paragraph__head">
                    <span class="discount-paragraph__text">{{
                        $lang.components.microDiscountFooter.related_categories
                    }}</span>
                </div>
                <ul class="discount-paragraph__list">
                    <li v-for="(item, index) in info.similar_categories.slice(0, 5)" :key="index">
                        <NuxtLink
                            no-prefetch
                            :to="`/${$lang.routes.categories}/${item.slug}`"
                            class="discount-paragraph__link"
                            rel="follow"
                        >
                            {{ item.name }}
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink
                            no-prefetch
                            :to="`/${$lang.routes.categories}`"
                            class="discount-paragraph__list-seemore"
                            rel="follow"
                        >
                            {{ $lang.components.microDiscountFooter.see_more_categories }}
                            <img :src="$assets.primary.arrowRightCategory" alt="Ver mas" />
                        </NuxtLink>
                    </li>
                </ul>
            </div>

            <div v-if="info.similar_stores" class="discount-paragraph">
                <div class="discount-paragraph__head">
                    <span class="discount-paragraph__text">{{
                        $lang.components.microDiscountFooter.related_stores
                    }}</span>
                </div>
                <ul class="discount-paragraph__list">
                    <li v-for="(item, index) in info.similar_stores.slice(0, 5)" :key="index">
                        <NuxtLink
                            no-prefetch
                            :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${item.slug}`"
                            class="discount-paragraph__link"
                            rel="follow"
                        >
                            {{ item.name }}
                        </NuxtLink>
                    </li>
                </ul>
            </div>

            <div v-if="info.popular_stores" class="discount-paragraph">
                <div class="discount-paragraph__head">
                    <span class="discount-paragraph__text">{{
                        $lang.components.microDiscountFooter.popular_stores
                    }}</span>
                </div>
                <ul class="discount-paragraph__list">
                    <li v-for="(item, index) in info.popular_stores.slice(0, 5)" :key="index">
                        <NuxtLink
                            no-prefetch
                            :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${item.slug}`"
                            class="discount-paragraph__link"
                            rel="follow"
                        >
                            {{ item.name }}
                        </NuxtLink>
                    </li>
                    <li>
                        <NuxtLink
                            no-prefetch
                            :to="`/${$lang.routes.brands}`"
                            class="discount-paragraph__list-seemore"
                            rel="follow"
                        >
                            {{ $lang.components.microDiscountFooter.see_more_stores }}
                            <img :src="$assets.primary.arrowRightCategory" alt="Ver mas" />
                        </NuxtLink>
                    </li>
                </ul>
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import type { Models } from '~/types/models'

export default defineComponent({
    name: 'DiscountDetailsFooter',
    props: {
        info: {
            type: Object as PropType<{
                similar_categories?: Models.Category[]
                similar_stores?: Models.Store[]
                popular_stores?: Models.Store[]
            }>,
            required: true,
        },
    },
})
</script>

<style lang="postcss" scoped>
.discount-details-footer {
    @apply mt-5 flex bg-white sm:justify-center sm:rounded-xl;
    &-wrapper {
        @apply divide-y sm:divide-y-0;
        @apply container flex flex-row flex-wrap gap-5 pb-5 pt-2 sm:py-5 md:grid md:grid-cols-3 lg:col-span-3 lg:grid-cols-3;
        .discount-paragraph {
            @apply w-full sm:w-fit md:mx-auto;
            &__head {
                @apply mt-3 flex gap-1 sm:mb-2 sm:mt-0;
            }
            &__text {
                @apply text-lg font-semibold text-gray-800 sm:text-xl;
            }
            &__list {
                @apply space-y-2;
                &-seemore {
                    @apply flex items-center gap-1 text-sm font-semibold text-site-primary hover:underline sm:text-base;
                    img {
                        @apply h-3 w-3 sm:h-4 sm:w-4;
                    }
                }
            }
            &__link {
                @apply text-sm text-gray-800 transition-colors duration-100 hover:text-black hover:underline sm:text-base;
            }
        }
    }
}
</style>
