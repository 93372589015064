<template>
    <article class="featured-store-item">
        <NuxtLink
            no-prefetch
            :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${info.slug}`"
        >
            <picture class="featured-store-item__banner">
                <img
                    :src="info.cover && info.cover.url ? info.cover.url : defaultImage.store"
                    :alt="info.name"
                    :title="info.name"
                    loading="lazy"
                    :onerror="`this.onerror=null;this.src='${defaultImage.store}'`"
                />
            </picture>
            <div class="featured-store-item__logo">
                <img v-if="info.image" :src="info.image.url" :alt="info.name" />
            </div>

            <h2 class="featured-store-item__title">
                {{ info.name }}
            </h2>
        </NuxtLink>
    </article>
</template>

<script lang="ts">
import type { Models } from '~/types/models'
import { useVariantsDictionary } from '~/composables/VariantsDictionary'

export default defineComponent({
    name: 'CardFeaturedStore',
    props: {
        info: {
            type: Object as PropType<Models.Store>,
            required: true,
        },
    },
    data() {
        const { defaultImages } = useVariantsDictionary()
        return {
            defaultImage: defaultImages,
        }
    },
})
</script>

<style lang="postcss" scoped>
.featured-store-item {
    @apply relative w-72 items-center space-x-2 rounded-xl border bg-white p-2 xs:w-[326px];
    &__banner {
        @apply block h-36 w-full;
        img {
            @apply h-full w-full rounded-2xl object-cover;
        }
    }
    &__logo {
        @apply absolute left-1/2 top-1/2 h-16 w-16 -translate-x-1/2 translate-y-3 transform overflow-hidden rounded-full border bg-white lg:h-20 lg:w-20 lg:-translate-y-1;
        img {
            @apply mx-auto h-full w-full object-contain p-1;
        }
    }

    &__title {
        @apply line-clamp-1 pt-8 text-center text-base font-medium text-gray-900 sm:pt-12 lg:text-lg;
    }
}
</style>
