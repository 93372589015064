<template>
    <div class="store-summary-data">
        <section class="summary-data">
            <h3 class="summary-data__title">
                {{ title }}
            </h3>

            <ul class="summary-data__list">
                <li>
                    <span>&#128527;</span>
                    <span
                        >{{ $lang.components.sidebarOfferSummary.coupons_for_shop }}
                        <span class="font-semibold text-site-primary">{{
                            info.coupons_count || 0
                        }}</span></span
                    >
                </li>
                <li>
                    <span>&#129321;</span>

                    <span
                        >{{ $lang.components.sidebarOfferSummary.offers_for_shop }}
                        <span class="font-semibold text-site-primary">{{
                            info.offers_count || 0
                        }}</span></span
                    >
                </li>
                <li>
                    <span>&#128336;</span>
                    <span
                        >{{ $lang.components.sidebarOfferSummary.last_update }}
                        <span class="font-semibold text-site-primary">{{ lastUpdate }}</span></span
                    >
                </li>
                <li>
                    <span>&#128293;</span>

                    <span
                        >{{ $lang.components.sidebarOfferSummary.last_week }}
                        <span class="font-semibold text-site-primary">{{
                            info.discounts_updated
                        }}</span></span
                    >
                </li>
                <li>
                    <span>&#128131;</span>

                    <span
                        >{{ $lang.components.sidebarOfferSummary.all_discounts }}
                        <span class="font-semibold text-site-primary">{{
                            info.discounts_active || 0
                        }}</span></span
                    >
                </li>
            </ul>
        </section>
        <section class="summary-rating" :class="{ grayscale: userRatingNow }">
            <h3 class="summary-rating__title">
                Ayuda calificando a esta tienda <span class="font-semibold">{{ store.name }}</span>
            </h3>
            <div class="summary-rating__stars">
                <div
                    ref="starsRatingContainer"
                    class="summary-rating__stars-container"
                    @mouseleave="onMouseLeave"
                >
                    <svg
                        v-for="star in stars"
                        :key="star"
                        style="cursor: pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30px"
                        height="30px"
                        viewBox="0 0 21 21"
                        @mouseenter.self="(e) => onMouseEnter(e)"
                        @click="() => onRating(star)"
                    >
                        <path
                            id="Trazado_3862"
                            data-name="Trazado 3862"
                            d="M70.045,23.277l-6.8-.661-2.7-6.579a.535.535,0,0,0-1,0l-2.7,6.579-6.8.661a.551.551,0,0,0-.469.393.589.589,0,0,0,.16.606L54.865,29l-1.5,6.986a.585.585,0,0,0,.214.584.524.524,0,0,0,.6.028l5.872-3.649,5.872,3.658a.524.524,0,0,0,.6-.028A.585.585,0,0,0,66.726,36l-1.5-6.986,5.132-4.727a.589.589,0,0,0,.165-.61.55.55,0,0,0-.475-.393Z"
                            transform="translate(-49.546 -15.69)"
                            fill="#eeeeee"
                        />
                    </svg>
                </div>
                <p class="summary-rating__stars-count">{{ totalRatingStars }}/5</p>
            </div>
            <div class="space-y-2">
                <p v-for="(f, i) in ratingFeedback" :key="i" class="summary-rating__explain">
                    {{ f }}
                </p>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'
import { parseISO, format } from 'date-fns'
import type { Models } from '~/types/models'
import { useRootStore } from '~/store/root'
import { useAuthStore } from '~/store/auth'
import type { Api } from '~~/global'

export default defineComponent({
    name: 'SidebarOfferSummary',
    props: {
        title: {
            type: String,
        },
        store: {
            type: Object as PropType<Models.Store>,
            required: true,
        },
        info: {
            type: Object as PropType<{
                discounts_active: number
                offers_count: number
                coupons_count: number
                discounts_updated: number
                last_updated: string
            }>,
            required: true,
        },
        model: {
            type: String,
        },
    },
    data() {
        const StoreValuation = useStoreValuation(this.store.id)

        const { setSiteNotification } = useRootStore()
        const { IsAuth, setAuthModal, SessionToken, updateActivitiesCount } = useAuthStore()
        return {
            StoreValuation,
            updateActivitiesCount,
            SessionToken,
            IsAuth,
            setAuthModal,
            setSiteNotification,
            images: [
                'https://cdn.worldvectorlogo.com/logos/visa.svg',
                'https://cdn.worldvectorlogo.com/logos/mastercard-2.svg',
                'https://cdn.worldvectorlogo.com/logos/american-express-1.svg',
            ],
            stars: [1, 2, 3, 4, 5],
            starsElements: null as null | HTMLCollection,
            userRating: null as null | number,
            userRatingNow: false,
            totalRatingStars: this.store.score || 0,
            totalRatingsCount: this.store.ratings_count || 0,
            averageScore: this.store.score,
        }
    },
    computed: {
        lastUpdate(): string {
            return format(parseISO(this.info.last_updated), 'dd/MM/yyyy')
        },
        ratingFeedback(): string[] {
            if (
                this.userRating ||
                (this.$config.public.variant !== 'megadescuentos' && this.StoreValuation.isValuated)
            ) {
                const feedback1 = `Hay ${this.totalRatingsCount} ${
                    this.totalRatingsCount > 1 ? 'votos' : 'voto'
                } y la media es ${this.averageScore}`

                const feedback2 = `¡Gracias por tu valoración!`

                return [feedback1, feedback2]
            } else if (this.store.score) {
                const feedback1 = `${this.store.ratings_count || 0} ${
                    (this.store.ratings_count || 0) > 1 ? 'usuarios' : 'usuario'
                } ${(this.store.ratings_count || 0) > 1 ? 'votaron' : 'votó'} y la media es de ${
                    this.averageScore
                }`

                return [feedback1]
            } else {
                const feedback1 = `Dar clic para calificar a la tienda ${this.store.name}`
                return [feedback1]
            }
        },
        starsCanBeStyledOnMouseEvents(): boolean {
            return (
                (!this.userRatingNow && !this.userRating) ||
                (this.$config.public.variant !== 'megadescuentos' && !this.StoreValuation.isValuated)
            )
        },
    },
    mounted() {
        /* this.$forceUpdate() */

        this.starsElements = (this.$refs.starsRatingContainer as HTMLDivElement).children

        this.resetStars()
    },
    methods: {
        resetStars() {
            if (this.store && this.store.score && this.starsElements) {
                let i = 0

                while (i < 5) {
                    const color = Math.round(this.store.score) < i + 1 ? '#eeeeee' : '#ecd500'

                    const firstChild = this.starsElements[i].firstChild as HTMLElement

                    if (firstChild) {
                        firstChild.setAttribute('fill', color)
                    }

                    i++
                }
            }
        },
        onMouseEnter(e: MouseEvent) {
            if (this.starsElements && this.starsCanBeStyledOnMouseEvents) {
                let targetIluminated = false

                let i = 0

                while (i < this.starsElements.length) {
                    const color = targetIluminated ? '#eeeeee' : '#ecd500'

                    const firstChild = this.starsElements[i]?.firstChild as HTMLElement

                    if (firstChild) {
                        firstChild.setAttribute('fill', color)
                    }

                    if (e.target === this.starsElements[i]) targetIluminated = true

                    i++
                }
            }
        },
        onMouseLeave() {
            if (this.starsCanBeStyledOnMouseEvents) {
                this.resetStars()
            }
        },
        async onRating(rating: number) {
            if (this.$config.public.variant !== 'megadescuentos' && this.StoreValuation.isValuated) {
                return
            }

            if (this.$config.public.variant === 'megadescuentos' && !this.IsAuth) {
                this.setAuthModal({
                    type: 'sign-in',
                })
                return
            }

            if (
                (/(bchollos|jdescuentos|cupohostin)/.test(this.$config.public.variant) ||
                    !this.store.user_rating) &&
                !this.userRatingNow &&
                !this.userRating
            ) {
                this.userRatingNow = true
                ;(this.$refs.starsRatingContainer as HTMLDivElement).style.cursor = 'wait'

                const { buildHeaders, baseURL, endpoints } = useApiConfig()

                const ratingResult = await $fetch<Api.Responses.General.RatingStore>(
                    endpoints.general.stores.rating,
                    {
                        headers: buildHeaders(
                            this.$config.public.variant === 'megadescuentos' ? this.SessionToken : undefined,
                        ),
                        method: 'POST',
                        onResponse: () => {
                            if (this.$config.public.variant === 'megadescuentos') {
                                this.updateActivitiesCount('notifications')
                            }
                        },
                        body: {
                            store_id: this.store.id,
                            grade: rating,
                        },
                        baseURL,
                    },
                ).catch<Api.Responses.General.RatingStore>((e) => e.data)

                if (ratingResult?.feedback === 'data_success') {
                    if (this.$config.public.variant !== 'megadescuentos') this.StoreValuation.valuate()

                    this.userRating = rating

                    this.totalRatingStars = rating

                    this.averageScore = ratingResult.data.new_score

                    this.totalRatingsCount = ratingResult.data.ratings_count

                    if (this.starsElements) {
                        let i = 0

                        while (i < 5) {
                            const color = this.totalRatingStars < i + 1 ? '#eeeeee' : '#ecd500'

                            const firstChild = this.starsElements[i].firstChild as HTMLElement

                            if (firstChild) {
                                firstChild.setAttribute('fill', color)
                            }

                            i++
                        }
                    }

                    this.setSiteNotification({
                        type: 'success',
                        text: 'Gracias por darnos tu opinion de ' + this.store.name,
                    })
                } else if (this.$config.public.variant === 'megadescuentos') {
                    if (ratingResult?.feedback === 'verify_email') {
                        this.setSiteNotification({
                            type: 'warning',
                            text: 'Para valorar una tienda necesita verificar su cuenta.',
                        })
                    } else if (ratingResult?.feedback === 'token_not_found') {
                        this.setSiteNotification({
                            type: 'error',
                            text: 'Algo salio mal',
                        })
                    }
                } else {
                    this.setSiteNotification({
                        type: 'error',
                        text: 'Algo salió mal. Intenta mas tarde',
                    })
                }

                ;(this.$refs.starsRatingContainer as HTMLDivElement).style.cursor = 'auto'

                this.userRatingNow = false
            }
        },
    },
})
</script>

<style lang="postcss" scoped>
.store-summary-data {
    @apply flex flex-col gap-5 rounded-xl bg-white px-8 py-6 md:flex-row;
    .summary-data {
        @apply flex-none md:w-1/2 lg:w-2/3;
        &__title {
            @apply mb-3 text-lg font-semibold;
        }
        &__list {
            @apply space-y-2.5 lg:flex lg:flex-wrap lg:gap-y-4 lg:space-y-0;
            li {
                @apply flex items-center space-x-1 leading-4 lg:w-1/2 lg:flex-none;
                > span {
                    @apply block;
                    &:first-child {
                        @apply text-xl;
                    }
                }
            }
        }
    }
    .summary-rating {
        @apply flex flex-col;
        &__title {
            @apply mb-3 text-lg;
        }
        &__stars {
            @apply flex items-baseline space-x-4;
            @apply mb-3;
            &-container {
                @apply space-x-2;
                svg {
                    @apply inline-block;

                    path {
                        transition: fill 0.5s;
                    }

                    &:not(:last-of-type) {
                        margin-right: 5px;
                    }
                }
            }
            &-count {
                @apply text-gray-800;
            }
        }
        &__explain {
            @apply text-xs text-gray-800;
        }
    }
}
</style>
