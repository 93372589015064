<template>
    <ol class="sitemap-discounts-index">
        <li v-for="(discount, keyB) in responseData" :key="keyB">
            <NuxtLink :to="variants[discount.type].path + '/' + discount.slug">{{ discount.title }}</NuxtLink>
        </li>
    </ol>
</template>

<script lang="ts" setup>
import { useVariantsDictionary } from '~/composables/VariantsDictionary'
import type { Models } from '~/types/models'
const { variants } = useVariantsDictionary()

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('stores-sitemap-data', () => {
    return $fetch<Models.Discount[]>(endpoints.pages.siteMap.discounts, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
    })
})

if (!responseData.value || !(responseData.value instanceof Array)) {
    throw createError({
        statusCode: 500,
        message: 'Algo salió mal',
    })
}
</script>
<style lang="postcss">
.sitemap-discounts-index {
    @apply list-inside list-decimal space-y-3 text-lg marker:font-semibold marker:text-site-primary;
    a {
        @apply lg:hover:underline;
    }
}
</style>
