import jsCookie from 'js-cookie'

import constants from '~/config/constants'

export const useSetCookies =
    () =>
    (expires = 0, token?: string) => {
        if (!!expires && token) {
            jsCookie.set(constants.cookies.access_token_key, token, {
                path: '/',
                expires: new Date(expires),
                sameSite: 'strict',
            })
            jsCookie.set(constants.cookies.token_expires_at_key, new Date(expires).toString(), {
                path: '/',
                expires: new Date(expires),
                sameSite: 'strict',
            })
        }
    }
