<template>
    <div>
        <section class="stores-gallery">
            <ul>
                <li v-for="item in stores" :key="item.id">
                    <NuxtLink
                        no-prefetch
                        :to="`${$config.public.variant !== 'cupohosting' ? `/${$lang.routes.brands}` : ''}/${item.slug}`"
                        rel="follow"
                    >
                        <picture>
                            <img
                                v-if="item.image"
                                :src="item.image.url"
                                :alt="item.name"
                                :title="item.name"
                                width="10px"
                                height="10px"
                                loading="lazy"
                                :onerror="`this.onerror=null;this.src='${defaultImage}'`"
                            />
                        </picture>
                    </NuxtLink>
                </li>
            </ul>
        </section>
    </div>
</template>

<script lang="ts" setup>
import type { Models } from '~/types/models'

const {
    defaultImages: { store: defaultImage },
} = useVariantsDictionary()

const pros = defineProps({
    stores: {
        type: Array as PropType<Models.Store[]>,
        required: true,
    },
})
</script>

<style lang="postcss" scoped>
.stores-gallery {
    @apply space-y-3;
    ul {
        @apply grid grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6;
        li {
            @apply h-20 w-full overflow-hidden rounded-xl border bg-white hover:border-gray-300;
            a {
                img {
                    @apply h-full w-full object-contain p-2;
                }
            }
        }
    }
}
</style>
