
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as discount_45maskj9HVw2dGOTMeta } from "~/views/discount-mask.vue?macro=true";
import { default as store_45maskrrOfhZN0pkMeta } from "~/views/store-mask.vue?macro=true";
import { default as store_45landing6Fs2zRsHqVMeta } from "~/views/store-landing.vue?macro=true";
import { default as homepage_45smallAhlhoSsfK4Meta } from "~/views/homepage-small.vue?macro=true";
import { default as store_45bridgeRyrhTqaIb2Meta } from "~/views/store-bridge.vue?macro=true";
import { default as category_45bridgepUYmiO2rTXMeta } from "~/views/category-bridge.vue?macro=true";
import { default as discount_45detailsAu0njxb3NgMeta } from "~/views/discount-details.vue?macro=true";
import { default as acerca_45de_45jdescuentosAJxd90WbGkMeta } from "~/views/acerca-de-jdescuentos.vue?macro=true";
import { default as aviso_45legal27GreimsDMMeta } from "~/views/aviso-legal.vue?macro=true";
import { default as ayuda6cYlYHtpPuMeta } from "~/views/ayuda.vue?macro=true";
import { default as indexPUvMN4GjS1Meta } from "~/views/blog/index.vue?macro=true";
import { default as contactouEjPCNMSn5Meta } from "~/views/contacto.vue?macro=true";
import { default as cookiesZdG9x55enXMeta } from "~/views/cookies.vue?macro=true";
import { default as cuponescYUW8ZiMjPMeta } from "~/views/cupones.vue?macro=true";
import { default as indexrC7BL1lmx3Meta } from "~/views/eventos/index.vue?macro=true";
import { default as expirados2cVA3uut0qMeta } from "~/views/expirados.vue?macro=true";
import { default as faqknATEkiae7Meta } from "~/views/faq.vue?macro=true";
import { default as gratisMnPRtxOIQDMeta } from "~/views/gratis.vue?macro=true";
import { default as ofertas1iv4IpaQurMeta } from "~/views/ofertas.vue?macro=true";
import { default as politicas_45de_45privacidadnm6yFCqQsmMeta } from "~/views/politicas-de-privacidad.vue?macro=true";
import { default as prensajve8ab1rGMMeta } from "~/views/prensa.vue?macro=true";
import { default as reglas_45de_45comunidadOV9dTNAqzDMeta } from "~/views/reglas-de-comunidad.vue?macro=true";
import { default as searchJXWnylL0IyMeta } from "~/views/search.vue?macro=true";
import { default as indexw4U5mpNMlrMeta } from "~/views/blog/[category]/index.vue?macro=true";
import { default as _91slug_93Rh3p8BZ1JvMeta } from "~/views/eventos/[slug].vue?macro=true";
import { default as _91article_930CtyZBH1FtMeta } from "~/views/blog/[category]/[article].vue?macro=true";
import { default as indexFNYRkGxMcmMeta } from "~/views/sitemap/index.vue?macro=true";
import { default as component_45stubGZr1UkTkg3Meta } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubGZr1UkTkg3 } from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "open-slug",
    path: "/open/:slug",
    component: () => import("~/views/discount-mask.vue")
  },
  {
    name: "go-id",
    path: "/go/:id",
    component: () => import("~/views/discount-mask.vue")
  },
  {
    name: "go-cupones-descuento-id",
    path: "/go/cupones-descuento/:id",
    meta: store_45maskrrOfhZN0pkMeta || {},
    component: () => import("~/views/store-mask.vue")
  },
  {
    name: "landing-slug",
    path: "/landing/:slug",
    component: () => import("~/views/store-landing.vue")
  },
  {
    name: "homepage",
    path: "/",
    component: () => import("~/views/homepage-small.vue")
  },
  {
    name: "cupones-descuento",
    path: "/cupones-descuento",
    component: () => import("~/views/store-bridge.vue")
  },
  {
    name: "cupones-descuento-slug",
    path: "/cupones-descuento/:slug",
    component: () => import("~/views/store-bridge.vue")
  },
  {
    name: "promociones-slug",
    path: "/promociones/:slug",
    component: () => import("~/views/category-bridge.vue")
  },
  {
    name: "promociones",
    path: "/promociones",
    component: () => import("~/views/category-bridge.vue")
  },
  {
    name: "cupones-slug",
    path: "/cupones/:slug",
    component: () => import("~/views/discount-details.vue")
  },
  {
    name: "ofertas-slug",
    path: "/ofertas/:slug",
    component: () => import("~/views/discount-details.vue")
  },
  {
    name: acerca_45de_45jdescuentosAJxd90WbGkMeta?.name,
    path: "/acerca-de-nosotros",
    component: () => import("~/views/acerca-de-jdescuentos.vue")
  },
  {
    name: aviso_45legal27GreimsDMMeta?.name,
    path: "/terminos-de-uso",
    component: () => import("~/views/aviso-legal.vue")
  },
  {
    name: ayuda6cYlYHtpPuMeta?.name,
    path: "/ayuda",
    component: () => import("~/views/ayuda.vue")
  },
  {
    name: indexPUvMN4GjS1Meta?.name,
    path: "/blog",
    component: () => import("~/views/blog/index.vue")
  },
  {
    name: category_45bridgepUYmiO2rTXMeta?.name,
    path: "/promociones",
    component: () => import("~/views/category-bridge.vue")
  },
  {
    name: contactouEjPCNMSn5Meta?.name,
    path: "/contacto",
    component: () => import("~/views/contacto.vue")
  },
  {
    name: cookiesZdG9x55enXMeta?.name,
    path: "/cookies",
    component: () => import("~/views/cookies.vue")
  },
  {
    name: cuponescYUW8ZiMjPMeta?.name,
    path: "/cupones",
    component: () => import("~/views/cupones.vue")
  },
  {
    name: indexrC7BL1lmx3Meta?.name,
    path: "/eventos",
    component: () => import("~/views/eventos/index.vue")
  },
  {
    name: expirados2cVA3uut0qMeta?.name,
    path: "/expirados",
    component: () => import("~/views/expirados.vue")
  },
  {
    name: faqknATEkiae7Meta?.name,
    path: "/faq",
    component: () => import("~/views/faq.vue")
  },
  {
    name: gratisMnPRtxOIQDMeta?.name,
    path: "/gratis",
    component: () => import("~/views/gratis.vue")
  },
  {
    name: store_45bridgeRyrhTqaIb2Meta?.name,
    path: "/cupones-descuento",
    component: () => import("~/views/store-bridge.vue")
  },
  {
    name: ofertas1iv4IpaQurMeta?.name,
    path: "/ofertas",
    component: () => import("~/views/ofertas.vue")
  },
  {
    name: politicas_45de_45privacidadnm6yFCqQsmMeta?.name,
    path: "/politica-de-privacidad",
    component: () => import("~/views/politicas-de-privacidad.vue")
  },
  {
    name: prensajve8ab1rGMMeta?.name,
    path: "/prensa",
    component: () => import("~/views/prensa.vue")
  },
  {
    name: reglas_45de_45comunidadOV9dTNAqzDMeta?.name,
    path: "/reglas-del-sitio",
    component: () => import("~/views/reglas-de-comunidad.vue")
  },
  {
    name: searchJXWnylL0IyMeta?.name,
    path: "/search",
    component: () => import("~/views/search.vue")
  },
  {
    name: indexw4U5mpNMlrMeta?.name,
    path: "/blog/:category",
    component: () => import("~/views/blog/[category]/index.vue")
  },
  {
    name: category_45bridgepUYmiO2rTXMeta?.name,
    path: "/promociones/:slug",
    component: () => import("~/views/category-bridge.vue")
  },
  {
    name: discount_45detailsAu0njxb3NgMeta?.name,
    path: "/cupones/:slug",
    component: () => import("~/views/discount-details.vue")
  },
  {
    name: _91slug_93Rh3p8BZ1JvMeta?.name,
    path: "/eventos/:slug",
    component: () => import("~/views/eventos/[slug].vue")
  },
  {
    name: store_45bridgeRyrhTqaIb2Meta?.name,
    path: "/cupones-descuento/:slug",
    component: () => import("~/views/store-bridge.vue")
  },
  {
    name: discount_45detailsAu0njxb3NgMeta?.name,
    path: "/ofertas/:slug",
    component: () => import("~/views/discount-details.vue")
  },
  {
    name: _91article_930CtyZBH1FtMeta?.name,
    path: "/blog/:category/:article",
    component: () => import("~/views/blog/[category]/[article].vue")
  },
  {
    name: indexFNYRkGxMcmMeta?.name,
    path: "/sitemap",
    component: () => import("~/views/sitemap/index.vue")
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/a",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/b",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/c",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/d",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/e",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/f",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/g",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/h",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/i",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/j",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/k",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/l",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/m",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/n",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/o",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/p",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/q",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/r",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/s",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/t",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/u",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/v",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/w",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/x",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/y",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cupones-descuento/z",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/a",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/b",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/c",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/d",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/e",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/f",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/g",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/h",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/i",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/j",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/k",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/l",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/m",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/n",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/o",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/p",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/q",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/r",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/s",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/t",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/u",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/v",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/w",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/x",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/y",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/promociones/z",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/extension",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/extension-desinstalada",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/discusiones",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/cambiar-contrasena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/comentados",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/compartir",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/contrasena-actualizada-exitosamente",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/inicio-sin-contrasena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/login",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/nueva-contrasena",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/recuperar-password",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/registro",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/verificar-cuenta",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/dashboard",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/dashboard/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/compartir/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/discusiones/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/mensajes",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/notificaciones",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/novedades",
    component: component_45stubGZr1UkTkg3
  },
  {
    name: component_45stubGZr1UkTkg3Meta?.name,
    path: "/novedades/:pathMatch(.*)",
    component: component_45stubGZr1UkTkg3
  }
]