<template>
    <div class="user-list-forums-index-page">
        <DashCardRouter title="Mis discusiones" />
        <div v-if="forums.length" class="user-list-forums-index-page-table">
            <div class="user-list-forums-index-page-table__body">
                <ul>
                    <li v-for="(forum, key) in forums" :key="key">
                        <div class="spacing-title title">
                            <NuxtLink
                                no-prefetch
                                :to="`/${$lang.routes.forum}/${forum.slug}`"
                                class="title-description"
                                >{{ forum.title }}</NuxtLink
                            >
                            <div class="title-footer">
                                <span>
                                    {{ forum.created_at && formatDate(forum.created_at) }}
                                </span>

                                <span>{{ forum.category ? forum.category.name : 'Todo' }}</span>

                                <NuxtLink
                                    no-prefetch
                                    :to="`/${$lang.routes.forum}/${forum.slug}`"
                                    class="flex"
                                >
                                    <img :src="$assets.gray.comment" alt="cantidad de respuestas" />&nbsp;
                                    {{ forum.comments_count || 0 }}
                                </NuxtLink>
                            </div>
                        </div>
                        <button class="spacing-category category">
                            {{ forum.category ? forum.category.name : 'Todo' }}
                        </button>
                        <p class="spacing-time time">
                            {{ forum.created_at && formatDate(forum.created_at) }}
                        </p>
                        <p class="spacing-answers answers">
                            <img :src="$assets.gray.comment" alt="cantidad de respuestas" />
                            {{ forum.comments_count || 0 }}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else>
            <MiscNoResults
                mode="chats"
                title="¡outs! al parecer aun no has creado o partipado en discusiones"
            />
            <div class="no-activity">
                <NuxtLink
                    no-prefetch
                    class="no-activity__btn"
                    :to="`/${$lang.routes.share}/${$lang.routes.chat}`"
                    >¡Comienza ya!</NuxtLink
                >
            </div>
        </div>
        <ButtonLoadMore v-if="fetchButton.show" :loading="fetchButton.loading" :handler="getMoreForums" />
    </div>
</template>

<script lang="ts" setup>
import { format, parseISO } from 'date-fns'
import { es as locale } from 'date-fns/locale'
import { useAuthStore } from '~/store/auth'
import type { Models } from '~/types/models'

import type { Api } from '~~/global'

const { UserData, SessionToken } = useAuthStore()
const { $lang } = useNuxtApp()

const params = reactive({
    per_page: 10,
    page: 1,
    user_id: UserData?.id,
})
const { buildHeaders, baseURL, endpoints } = useApiConfig()
const getForums = () =>
    $fetch<Api.Responses.General.IndividualModel<Models.Forum>>(endpoints.general.forums.path, {
        headers: buildHeaders(SessionToken),
        baseURL,
        method: 'GET',
        params,
    })

const { data: responseData, error } = await useAsyncData('dashboard-user-activities', () =>
    getForums().catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: 404,
        message: responseData.value?.feedback || error.value?.message || 'Error data',
    })
}

const forums = ref(responseData.value.data.results)

const fetchButton = reactive({
    loading: false,
    show:
        responseData.value.data.meta.total > 0 &&
        responseData.value.data.meta.current_page < responseData.value.data.meta.last_page,
})

const Route = useRoute()
const {
    public: { origin, siteName },
} = useRuntimeConfig()

const title = 'Chats | ' + siteName
const description = 'Chats que has publicado en ' + siteName

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

const formatDate = (date: string) => {
    return format(parseISO(date), 'd MMM', { locale })
}

const getMoreForums = async () => {
    fetchButton.loading = true

    const { feedback, data } = await getForums()

    if (feedback === 'data_success') {
        forums.value = [...forums.value, ...data.results]

        if (data.meta.current_page < data.meta.last_page) {
            params.page = data.meta.current_page + 1
        } else {
            fetchButton.show = false
        }
    }

    fetchButton.loading = false
}
</script>

<style lang="postcss" scoped>
.user-list-forums-index-page {
    @apply bg-white p-2 xs:rounded-xl;
    &-table {
        @apply min-w-full text-gray-800;
        &__message {
            @apply w-full bg-white py-6 text-center text-site-primary;
        }
        li {
            @apply flex w-full gap-0.5 bg-white p-2 text-sm text-gray-800;
            &:not(:last-of-type) {
                @apply border-b;
            }
            .author {
                @apply flex flex-col flex-wrap items-start gap-x-1 sm:flex-row sm:items-center;
                .image {
                    @apply h-9 w-9 flex-none rounded-full;
                    img {
                        @apply h-full w-full rounded-full object-cover;
                    }
                }
                .info {
                    &__user {
                        @apply overflow-hidden text-ellipsis whitespace-nowrap text-xs hover:underline;
                    }
                    &__rank {
                        @apply flex w-fit gap-1 self-center rounded-xl border bg-[#EFEEF2] p-1 text-xs text-[#3D3D3D];
                    }
                }
            }
            .title {
                @apply flex flex-col justify-between gap-1.5 lg:gap-0;
                &-description {
                    @apply line-clamp-2 text-sm font-semibold text-[#1B1B1B] hover:underline md:text-base;
                }
                &-footer {
                    @apply flex items-center justify-between font-sans text-xs text-gray-800 md:hidden;
                    img {
                        @apply w-3;
                    }
                }
            }
            .answers {
                @apply flex-wrap justify-center gap-1.5 font-medium;
                img {
                    @apply h-4;
                }
            }
        }
    }
    .spacing-title {
        @apply flex-grow lg:pr-10;
    }
    .spacing-category {
        @apply hidden w-1/4 flex-none items-center px-2 md:flex md:w-2/12;
    }
    .spacing-time {
        @apply hidden w-1/4 flex-none items-center md:flex md:w-1/12;
    }
    .spacing-answers {
        @apply hidden w-1/4 flex-none items-center md:flex md:w-1/12;
    }
}
.no-activity {
    @apply mb-5 mt-5 flex w-full flex-wrap items-center justify-center gap-2;
    &__btn {
        @apply rounded-xl bg-site-primary px-6 py-3 text-white transition duration-200 lg:hover:brightness-110;
    }
}
</style>
