<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import { replacePlaceholders } from '~/util/textFunction'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const currentDates = currentDate()
const { $lang } = useNuxtApp()
const Route = useRoute()
const AuthStore = useAuthStore()
const RootStore = useRootStore()

const params = reactive({
    page: 1,
})

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const headers = buildHeaders(AuthStore.SessionToken)

const fetchHome = () =>
    $fetch<Api.Responses.Pages.HomeSmall>(endpoints.pages.home.path, {
        method: 'GET',
        headers,
        baseURL,
        params,
    })

const config = useRuntimeConfig()

const { data: homepageData, error } = await useAsyncData('homepage-data', async () => {
    try {
        const pageData = await fetchHome()

        return {
            pageData,
        }
    } catch (error) {
        console.log(error)

        throw createError({
            statusCode: 500,
            message: 'Algo salió mal',
        })
    }
})

if (!homepageData.value) throw new Error('Homepage data not found')

params.page = 2

const { pageData } = homepageData.value

const featuredStores = computed(() => {
    return RootStore.layoutData?.data.sidebar.stores || []
})
const featuredCategories = computed(() => {
    return RootStore.layoutData?.data.sidebar.categories || []
})

const {
    public: { origin, siteName, variant },
} = useRuntimeConfig()
const [title, description] = replacePlaceholders(
    [
        [':month', currentDates.month || ''],
        [':day', String(currentDates.day) || ''],
        [':year', String(currentDates.year) || ''],
        [':site', siteName || ''],
    ],
    [pageData.page.title || '', pageData.page.description || ''],
)

useSeoMeta({
    title: title,
    ogUrl: origin + Route.path,
    ogTitle: title,
    description: description,
    ogDescription: description,
    ogImageAlt: title,
    twitterCard: 'summary_large_image',
    ...(Route.path !== '/' ? { robots: 'noindex' } : {}),
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})

useJsonld({
    '@context': 'https://schema.org',
    '@graph': [
        {
            '@type': 'WebSite' as 'WebSite',
            name: $lang.brand,
            url: origin,
        },
        {
            '@type': 'Organization' as 'Organization',
            name: $lang.brand,
            url: origin,
            brand: $lang.brand,
            logo: `${origin}/assets/schema.org/organisation/${variant}.png`,
            email: $lang.views.homepage.schemeOrg.email,
            founder: 'Juan Hurtado',
            sameAs: $lang.views.homepage.schemeOrg.sameAs,
            address: $lang.views.homepage.schemeOrg.address,
        },
    ],
})

const categoryOne = computed(() => {
    return pageData.page.data.discount_categories[0] || null
})
const categoryTwo = computed(() => {
    return pageData.page.data.discount_categories[1] || null
})
const categoryThree = computed(() => {
    return pageData.page.data.discount_categories[2] || null
})
</script>
<template>
    <div class="home-index">
        <section class="home-index__first-section">
            <h1 class="home-index__heading-1">{{ pageData.page.h1 }}</h1>
            <div class="home-index__section">
                <WidgetSlider class="h-[140px] xs:h-[120px] md:h-[180px] lg:h-auto" grid-variant>
                    <WidgetSliderItem
                        v-for="(item, index) in pageData.page.data.card_featured_offers.slice(0, 3)"
                    >
                        <CardHorizontal :key="index" :info="item" />
                    </WidgetSliderItem>
                </WidgetSlider>
            </div>
        </section>

        <section class="home-index__section">
            <h2 class="home-index__heading-2">
                {{ $lang.views.homepage.best_offers_and_coupons_from }}
                {{ currentDates.month }}
                {{ currentDates.year }}
            </h2>

            <ul class="discount-list">
                <template v-if="pageData.page.cta_cards && pageData.page.cta_cards.length">
                    <li
                        v-for="(cta, key) in pageData.page.cta_cards"
                        :key="'cta-' + key"
                        :style="{ order: cta.position }"
                        class="mb-3"
                    >
                        <CTAHandler :cta="cta" />
                    </li>
                </template>
                <li
                    v-for="(item, key) in pageData.page.data.popular_discounts"
                    :key="key"
                    :style="{ order: key + 1 }"
                    class="mb-3"
                >
                    <DelayHydration>
                        <DCardAdmin v-if="item.is_admin" :info="item" :show-brand-logo="true" />
                        <DCardMain v-else :info="item" in-stores-route="true" />
                    </DelayHydration>
                </li>
            </ul>
        </section>

        <section v-if="categoryOne && categoryOne.discounts.length" lass="home-index__section">
            <h2 class="home-index__heading-2">Descuentos en {{ categoryOne?.name }}</h2>

            <WidgetSlider class="h-[150px] md:h-[162px] lg:h-auto" :gridVariant="true">
                <WidgetSliderItem v-for="(item, index) in categoryOne.discounts" key="index">
                    <CardMulti
                        :info="item"
                        :variant="
                            index === 0 && categoryOne.discounts.length === 5 ? 'vertical' : 'horizontal'
                        "
                    ></CardMulti>
                </WidgetSliderItem>
            </WidgetSlider>
        </section>

        <section v-if="categoryTwo && categoryTwo.discounts.length" lass="home-index__section">
            <h2 class="home-index__heading-2">Descuentos en {{ categoryTwo?.name }}</h2>

            <WidgetSlider class="h-[150px] md:h-[162px] lg:h-auto" :gridVariant="true">
                <WidgetSliderItem v-for="(item, index) in categoryTwo.discounts" key="index">
                    <CardMulti
                        :info="item"
                        :variant="
                            index === 0 && categoryTwo.discounts.length === 5 ? 'vertical' : 'horizontal'
                        "
                    ></CardMulti>
                </WidgetSliderItem>
            </WidgetSlider>
        </section>

        <section lass="home-index__section">
            <h2 class="home-index__heading-2">{{ $lang.views.homepage.popular_stores }}</h2>

            <MiscStoreGallery :stores="featuredStores" />
        </section>

        <section v-if="categoryThree && categoryThree.discounts.length" lass="home-index__section">
            <h2 class="home-index__heading-2">Descuentos en {{ categoryThree?.name }}</h2>

            <WidgetSlider class="h-[150px] md:h-[162px] lg:h-auto" :gridVariant="true">
                <WidgetSliderItem v-for="(item, index) in categoryThree.discounts" key="index">
                    <CardMulti
                        :info="item"
                        :variant="
                            index === 0 && categoryThree.discounts.length === 5 ? 'vertical' : 'horizontal'
                        "
                    ></CardMulti>
                </WidgetSliderItem>
            </WidgetSlider>
        </section>

        <section lass="home-index__section">
            <h2 class="home-index__heading-2">{{ $lang.views.homepage.top_categories }}</h2>

            <MiscCategoriesChips :categories="featuredCategories" />
        </section>
    </div>
</template>

<style scoped lang="postcss">
.home-index {
    @apply container relative h-full max-w-5xl space-y-6 pb-14 pt-5 lg:space-y-8;

    &__heading-1 {
        @apply text-center text-xl font-medium sm:text-2xl;
    }

    &__heading-2 {
        @apply mb-3 text-lg font-semibold sm:text-xl;
    }

    &__first-section {
        @apply flex flex-col gap-y-4;
    }

    &__section {
        .discount-list {
            @apply flex flex-col;
        }

        @screen sm {
            .top-offer {
                width: calc(50% - theme('spacing[2.5]'));
            }
        }
        @screen lg {
            .top-offer {
                width: calc((100% / 3) - theme('spacing.2'));
            }
        }
    }

    & .favorite-stores__list {
        @apply grid grid-cols-2 gap-3 sm:grid-cols-3 lg:grid-cols-5;

        & li:nth-child(odd):last-child {
            @apply col-span-2 sm:col-span-1;
        }
    }

    & .category-card-list {
        @apply flex flex-wrap;

        &__item {
            @apply m-2 h-[52px] flex-grow items-center justify-center rounded-[4px] border text-center lg:duration-300 lg:hover:border-[1.5px];
        }
    }
}
</style>
